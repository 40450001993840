import React from "react";
import { Text, Pressable } from "react-native";

import AppStyles from "../AppStyles";
import {
  FetchViewerData,
} from "../Queries";

const MagicBeanButton = ({
  viewerData,
  navigateToPurchaseBean,
}: {
  viewerData: FetchViewerData
  navigateToPurchaseBean: () => void;
}) => {

  return (
    <Pressable
      style={{
        marginTop: 12,
        padding: 8,
        backgroundColor: "#8E2BFE",
        borderRadius: 12,
      }}
      onPress={async () => {
        console.log("bean button");

        navigateToPurchaseBean();
      }}
    >
      <Text
        style={{
          color: AppStyles.text.mainText.color,
          textAlign: "center",
          fontSize: 16,
          fontFamily: AppStyles.text.secondaryText.font,
        }}
      >
        <b>Buy Coffee Beans</b><br/>
        You have {viewerData?.viewer?.beans} Coffee Beans
      </Text>
    </Pressable>
  );
};

export default MagicBeanButton;
