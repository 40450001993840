import React, { ReactNode } from "react";
import { User } from "../Types";
import { gql, useMutation } from "@apollo/client";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { initUser } from "../Logging";

export const ACCESS_TOKEN_KEY = "@access_token";
const USER_DATA_KEY = "@user_data";

const USER_FRAGMENT = `
id
username
email`;

const LOGIN_FRAGMENT = `
accessToken
user {
  ${USER_FRAGMENT}
}`;

const LOGIN_USER = gql`
  mutation($data: UserLoginInput!) {
    login(data: $data) {
      ${LOGIN_FRAGMENT}
    }
  }
`;

const LOGIN_APPLE = gql`
  mutation($identityToken: String!, $clientId: String!) {
    loginApple(identityToken: $identityToken, clientId: $clientId) {
      ${LOGIN_FRAGMENT}
    }
  }
`;

interface UserLoginInput {
  email: string;
  password: string;
}

interface UserAppleLoginInput {
  identityToken: string;
  clientId: string;
}

interface UserData {
  id: number;
  username: string;
  email: string;
}

interface UserLoginData {
  accessToken: string;
  user: UserData;
}

const CREATE_USER = gql`
  mutation($data: UserCreateInput!) {
    signupUser(data: $data) {
      accessToken
      user {
        id
        username
        email
      }
    }
  }
`;

interface CreateUserInput {
  email: string;
  username: string;
  password: string;
}

interface CreateUserLoginData {
  accessToken: string;
  user: UserData;
}

type AuthContextType = {
  accessToken?: string;
  user?: User | null;
  userInitialized: boolean;
  login: (username: string, password: string) => Promise<void>;
  loginApple: (identityToken: string) => Promise<void>;
  createUser: (
    email: string,
    username: string,
    password: string
  ) => Promise<void>;
  logout: () => Promise<void>;
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

const useAuthContext = (): AuthContextType => {
  const value = React.useContext<AuthContextType | undefined>(AuthContext);
  if (value === undefined) {
    throw new Error("Did you use a provider?");
  }
  return value;
};

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [accessToken, setAccessToken] = React.useState<string>();
  const [user, setUser] = React.useState<User | null>();
  const [userInitialized, setUserInitialized] = React.useState<boolean>(false);

  React.useEffect(() => {
    AsyncStorage.multiGet([ACCESS_TOKEN_KEY, USER_DATA_KEY]).then((data) => {
      if (data.length >= 1) {
        const accessTokenData = data[0];
        const accessToken = accessTokenData[1];
        if (typeof accessToken === "string") {
          setAccessToken(accessToken);
        }
      }

      if (data.length >= 2) {
        const userData = data[1];
        const jsonUserData = userData[1];
        if (jsonUserData !== null) {
          const user = JSON.parse(userData[1] ?? "");
          setUser(user);
        } else {
          setUser(null);
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (!!accessToken) {
      AsyncStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (!!user) {
      AsyncStorage.setItem(USER_DATA_KEY, JSON.stringify(user));
      initUser({
        userId: user.id.toString(),
        username: user.username ?? '',
        email: user.email ?? '',
      });
      setUserInitialized(true);
    } else {
      setUserInitialized(false);
    }
  }, [user]);

  const [loginGraphql] = useMutation<
    { login: UserLoginData },
    { data: UserLoginInput }
  >(LOGIN_USER);

  const [loginAppleGraphql] = useMutation<
    {loginApple: UserLoginData},
    UserAppleLoginInput
    >(LOGIN_APPLE);

  const login = async (username: string, password: string) => {
    try {
      const response = await loginGraphql({
        variables: { data: { email: username, password: password } },
      });
      console.log(response);
      handleLoginResponse(response.data?.login);
    } catch (ex) {
      console.log(JSON.stringify(ex));
      throw "We couldn't log you in. Try again!";
    }
  };

  const loginApple = async (identityToken: string) => {
    try {
      const response = await loginAppleGraphql({
        variables: {identityToken: identityToken, clientId: "com.stori.webapp"},
      });
      console.log(response);
      handleLoginResponse(response.data?.loginApple);
    } catch (ex) {
      console.log(JSON.stringify(ex));
    }
  };

  const handleLoginResponse = (
    loginResponse: UserLoginData | null | undefined,
  ) => {
    if (!loginResponse) {
      throw new Error("We couldn't log you in. Try again!");
    }
    const accessToken = loginResponse.accessToken;
    setAccessToken(accessToken);
    const user = {
      id: loginResponse.user.id,
      username: loginResponse.user.username,
      email: loginResponse.user.email,
    };
    setUser(user);
  };

  const [createUserGraphql] = useMutation<
    { signupUser: CreateUserLoginData },
    { data: CreateUserInput }
  >(CREATE_USER);

  const createUser = async (
    email: string,
    username: string,
    password: string
  ) => {
    try {
      const response = await createUserGraphql({
        variables: {
          data: { email: email, username: username, password: password },
        },
      });
      const responseData = response.data;
      if (!responseData) {
        throw "We couldn't log you in. Try again!";
      }
      setAccessToken(responseData.signupUser.accessToken);
      setUser({
        id: responseData.signupUser.user.id,
        username: responseData.signupUser.user.username,
        email: responseData.signupUser.user.email,
      });
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  };

  const logout = async () => {
    await AsyncStorage.multiRemove([ACCESS_TOKEN_KEY, USER_DATA_KEY]);
    setUser(undefined);
    setAccessToken(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        userInitialized,
        login,
        loginApple,
        logout,
        createUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, useAuthContext };
