import React from "react";
import "./Shimmer.css";

const DEFAULT_DURATION_MS = 1600;

const calcShimmerStyle = (
  width: number,
  height: number,
  duration = DEFAULT_DURATION_MS
) => ({
  backgroundSize: `${width * 10}px ${height}px`,
  animationDuration: `${(duration / 1000).toFixed(1)}s`,
});

const Shimmer = ({
  width,
  duration,
  height,
  borderRadius,
}: {
  width: number;
  height: number;
  duration?: number;
  borderRadius?: number;
}) => {
  const shimmerStyle = calcShimmerStyle(width, height, duration);
  const style = {
    ...shimmerStyle,
    ...{ height: height, width: width, borderRadius: borderRadius ?? 0 },
  };

  return <div className="shimmer" style={style} />;
};

export default Shimmer;
