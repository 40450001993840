import React from "react";
import { Image, Text, Pressable, ScrollView, View, TextStyle, TouchableOpacity } from "react-native";

import AppStyles from "../AppStyles";
import { logEvent } from "../Logging";
import { Ionicons } from "@expo/vector-icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { FETCH_VIEWER, FetchViewerData } from "./ProfileScreen";

import Shimmer from "../components/Shimmer";
import Markdown from 'react-native-markdown-display';

const CHAPTER_QUERY = `
id
name
chapterIndex
isComplete
percentComplete
requiresPurchaseIgnoreSeason
seasonIndex
unlockedByCoupon
unlockedByReading
`;

const BOOK_DATA_QUERY = `
id
coverImageUrl
name
shortDescription
description
author {
  id
  name
  alias
}
chapters {
${CHAPTER_QUERY}
}
genres {
  id
  label
  displayName
}
latestChapterRead {
  seasonIndex
}
seasons {
  id
  seasonIndex
  name
  purchased
}`;

export const FETCH_BOOK = gql`
  query FetchBookByID($id: Int!) {
    bookById(id: $id) {
      ${BOOK_DATA_QUERY}
    }
  }
`;

const FAVORITE_BOOK = gql`
  mutation FavoriteBook($id: Int!, $favorite: Boolean!) {
    favoriteBook(id: $id, favorite: $favorite) {
      id
      favorited
      followerCount
    }
  }
`;

const CHAPTER_ROW_BORDER_RADIUS = 8;

const tabStyle: TextStyle = {
  paddingVertical: 8,
  textAlign: 'center',
  fontSize: AppStyles.tabHeadingsSize,
  fontFamily: AppStyles.text.secondaryText.font,
  color: 'white',
};

const activeTabStyle: TextStyle = {
  ...tabStyle,
  fontWeight: '800',
  color: '#8E2BFE',
};

const ChapterRow = ({
  index,
  percentComplete,
  isComplete,
  onPress,
  isLocked,
  chapterName,
  unlockedByCoupon,
  unlockedByReading,
}: {
  index: number;
  percentComplete: number;
  isComplete: boolean;
  onPress: () => void;
  isLocked: boolean;
  chapterName: string;
  unlockedByCoupon: boolean;
  unlockedByReading: boolean;
}) => {
  return (
    <Pressable
      style={{
        backgroundColor: "#333333",
        marginBottom: 8,
        borderRadius: CHAPTER_ROW_BORDER_RADIUS,
        overflow: "hidden",
      }}
      onPress={onPress}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          padding: 12,
        }}
      >
        <Ionicons
          name="book-outline"
          color={AppStyles.text.mainText.color}
          size={16}
        />
        <Text
          style={{
            color: AppStyles.text.mainText.color,
            fontSize: 16,
            marginLeft: 12,
            fontFamily: AppStyles.text.secondaryText.font,
          }}
        >
          {chapterName}
        </Text>
        {isLocked && (
          <Ionicons
            name="lock-closed"
            color={AppStyles.text.mainText.color}
            size={16}
            style={{ marginLeft: 12 }}
          />
        )}
        {unlockedByCoupon && (
        <Text
          style={{
            color: AppStyles.text.secondaryText.color,
            fontSize: 12,
            marginLeft: 30,
            marginTop: 3,
          }}>
          Limited Time Free
        </Text>
        )}
        {unlockedByReading && (
        <Text
          style={{
            color: AppStyles.text.secondaryText.color,
            fontSize: 12,
            marginLeft: 30,
            marginTop: 3,
          }}>
          Free
        </Text>
        )}
      </View>
      <View
        style={{
          backgroundColor: "white",
          minHeight: 5,
          width: isComplete ? "100%" : `${percentComplete}%`,
          borderBottomLeftRadius: CHAPTER_ROW_BORDER_RADIUS,
        }}
      ></View>
    </Pressable>
  );
};

interface Chapter {
  id: number;
  name: string;
  chapterIndex: number;
  isComplete: boolean;
  percentComplete: number;
  requiresPurchaseIgnoreSeason: boolean;
  seasonIndex: number;
  unlockedByCoupon: boolean;
  unlockedByReading: boolean;
}

interface LastChapter {
  seasonIndex: number;
}

interface Author {
  id: number;
  name: string;
  alias?: string;
}

interface Genre {
  id: string;
  label: string;
  displayName: string;
}

interface Seasons {
  id: number;
  name: string;
  seasonIndex: number;
  purchased: boolean;
}

interface BookData {
  id: number;
  coverImageUrl: string;
  name: string;
  description: string;
  chapters: Array<Chapter>;
  author: Author;
  seasons: Array<Seasons>;
  genres: Array<Genre>;
  latestChapterRead: LastChapter;
}

interface FetchBookData {
  bookById: BookData;
}

interface FavoriteData {
  __typename: String;
  id: number;
  favorited: boolean;
}

interface FavoriteBookData {
  __typename: String;
  favoriteBook: FavoriteData;
}

interface FavoriteBookInput {
  id: number;
  favorite: boolean;
}

const BookScreen = ({
  bookId,
  onBackPress,
  navigateToChapter,
  navigateToAuthor,
  navigateToGenre,
}: {
  bookId: number;
  onBackPress?: () => void;
  navigateToChapter: (chapterId: number) => void;
  navigateToAuthor: (authorId: string) => void;
  navigateToGenre: (genreId: string) => void;
}) => {
  const { data: fetchBookData } = useQuery<FetchBookData>(FETCH_BOOK, {
    variables: { id: bookId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [impressionLogged, setImpressionLogged] = React.useState(false);
  React.useEffect(() => {
    setImpressionLogged(false);
  }, [bookId]);
  React.useEffect(() => {
    if (!impressionLogged && !!fetchBookData) {
      setImpressionLogged(true);
      logEvent("view_book", {
        book_id: fetchBookData.bookById.id,
        book_name: fetchBookData.bookById.name,
      });
    }
  }, [fetchBookData, impressionLogged]);

  // const [favoriteBook] = useMutation<FavoriteBookData, FavoriteBookInput>(
  //   FAVORITE_BOOK,
  //   {
  //     update: (cache, { data: favoriteBookData }) => {
  //       const viewerData = cache.readQuery<FetchViewerData>({
  //         query: FETCH_VIEWER,
  //       });
  //
  //       if (viewerData !== null && !!favoriteBookData) {
  //         if (favoriteBookData.favoriteBook.favorited) {
  //           cache.writeQuery({
  //             query: FETCH_VIEWER,
  //             data: {
  //               viewer: {
  //                 ...viewerData.viewer,
  //                 favoritedBooks: [
  //                   favoriteBookData.favoriteBook,
  //                   ...(viewerData.viewer?.favoritedBooks || []),
  //                 ],
  //               },
  //             },
  //           });
  //         } else {
  //           cache.writeQuery({
  //             query: FETCH_VIEWER,
  //             data: {
  //               viewer: {
  //                 ...viewerData.viewer,
  //                 favoritedBooks: viewerData.viewer?.favoritedBooks.filter(
  //                   (bookData) =>
  //                     bookData.id !== favoriteBookData.favoriteBook.id
  //                 ),
  //               },
  //             },
  //           });
  //         }
  //       }
  //     },
  //   }
  // );

  const bookData = fetchBookData?.bookById;
  const seasons = bookData?.seasons;

  const [currentSeasonIndex, setCurrentSeasonIndex] = React.useState(
    bookData?.latestChapterRead?.seasonIndex ?? 1,
  );

  //Tabs
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <ScrollView
      style={{ backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <View
        style={{
          alignItems: "center",
          paddingVertical: 36,
          backgroundColor: "#3b3b3b",
        }}
      >
        <TouchableOpacity
          style={{ position: "absolute", left: 18, top: 18 }}
          onPress={onBackPress}
        >
          <Ionicons name="chevron-back" color="white" size={36} />
        </TouchableOpacity>
        {/* DISABLE FAVORITING FOR NOW
        {!!bookData && (
          <Pressable
            style={{ position: "absolute", right: 18, top: 18 }}
            onPress={() =>
              favoriteBook({
                variables: { id: bookId, favorite: !bookData?.favorited },
                optimisticResponse: {
                  __typename: "Mutation",
                  favoriteBook: {
                    __typename: "Book",
                    id: bookId,
                    favorited: !bookData?.favorited,
                  },
                },
              })
            }
          >
            <Ionicons
              name={bookData.favorited ? "star" : "star-outline"}
              color="white"
              size={28}
            />
          </Pressable>
        )} */}
        <View></View>
        {!!bookData ? (
          <Image
            source={{ uri: bookData.coverImageUrl }}
            style={{
              width: 200,
              height: 175,
            }}
            resizeMode="contain"
          />
        ) : (
          <Shimmer width={100} height={175} />
        )}
      </View>
      {!!bookData && (
        <>
          <View
            style={{
              alignItems: "center",
              backgroundColor: "#1b1b20",
              flex: 1,
              paddingHorizontal: 48,
              marginVertical: 36,
            }}
          >
            <Text
              style={{
                color: AppStyles.text.mainText.color,
                fontSize: 24,
                textAlign: "center",
                fontFamily: AppStyles.text.mainText.font
              }}
            >
              {bookData.name}
            </Text>
            <TouchableOpacity
              onPress={() =>
                navigateToAuthor(
                  bookData.author.alias ?? bookData.author.id.toString()
                )
              }
            >
              <Text
                style={{
                  color: AppStyles.text.mainText.color,
                  fontSize: 16,
                  textAlign: "center",
                  marginTop: 12,
                  fontFamily: AppStyles.text.secondaryText.font,
                  textDecorationLine: "underline",
                }}
              >
                {bookData.author.name}
              </Text>
            </TouchableOpacity>

            <View style={{ flexDirection: "row" }}>
              {bookData.genres.map((genre, index) => (
                <TouchableOpacity
                  key={genre.id}
                  onPress={() => navigateToGenre(genre.label)}
                >
                  <Text
                    style={{
                      color: AppStyles.text.secondaryText.color,
                      fontSize: 16,
                      textAlign: "center",
                      marginTop: 12,
                      fontFamily: AppStyles.text.secondaryText.font,
                    }}
                  >
                    {`${index !== 0 ? " · " : ""}${genre.displayName}`}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            {/* <Text
              style={{
                color: AppStyles.text.secondaryText.color,
                paddingHorizontal: 24,
                fontSize: 16,
                textAlign: "center",
                marginTop: 12,
              }}
            >
              {`${bookData.followerCount} following · ${bookData.readerCount} reading`}
            </Text> */}
            {/* <Text
              style={{
                color: AppStyles.text.mainText.color,
                fontSize: 16,
                marginTop: 48,
              }}
            >
              {bookData.description}
            </Text> */}
          </View>
          {seasons && seasons.length > 0 &&
                  <Markdown
                    style={{
                      body: {
                        color: AppStyles.text.mainText.color,
                        marginTop: 12,
                        marginLeft: 12,
                        fontFamily: AppStyles.text.secondaryText.font,
                      }
                    }}>
                    {seasons?.filter((season) => season.seasonIndex == currentSeasonIndex)[0].description}
                  </Markdown>
                }
          <View style={{ paddingHorizontal: 48, marginBottom: 24 }}>
          {seasons && seasons.length > 0 && (
                <View
                  style={{
                    backgroundColor: AppStyles.pageBackgroundColor,
                    borderTopColor: '#283139',
                    borderTopWidth: 1,
                    marginTop: 8,
                    marginBottom: 12,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      overflow: 'hidden',
                      borderTopColor: '#616161',
                      alignSelf: 'center',
                    }}>
                    {seasons.slice().sort((a, b) => (a.seasonIndex > b.seasonIndex) ? 1 : -1).
                            map((season, idx) => (
                      <Pressable
                        onPress={() => {
                          setActiveTab(idx);
                          setCurrentSeasonIndex(season.seasonIndex);
                        }}
                        style={{
                          flex: 1,
                          borderBottomColor: '#8E2BFE',
                          borderBottomWidth: activeTab === idx ? 1 : 0,
                          paddingHorizontal:40
                        }}>
                        <Text style={activeTab === idx ? activeTabStyle : tabStyle}>
                          {season.name + ' '}       
                        </Text>
                      </Pressable>
                    ))}
                  </View>
                </View>
              )}
          {seasons && seasons.length > 0 && bookData.chapters.filter((chapter) => {
                let season = seasons.find(s => s.seasonIndex === chapter.seasonIndex);
                return currentSeasonIndex === chapter.seasonIndex && season;
              }).map(chapter => {
                let season = seasons.find(s => s.seasonIndex === chapter.seasonIndex);
                return <ChapterRow
                  index={chapter.chapterIndex}
                  key={chapter.id}
                  onPress={() => {
                    navigateToChapter(chapter.id);
                  }}
                  percentComplete={chapter.percentComplete}
                  isComplete={chapter.isComplete}
                  isLocked={chapter.requiresPurchaseIgnoreSeason && !season!.purchased}
                  chapterName={chapter.name}
                  unlockedByCoupon={chapter.unlockedByCoupon}
                  unlockedByReading={chapter.unlockedByReading}
                />
              })}
          </View>
        </>
      )}
    </ScrollView>
  );
};

export default BookScreen;
