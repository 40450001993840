import React from "react";
import { Pressable, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";

import { useAuthContext } from "./context/AuthContext";
import { logEvent } from "./Logging";
import AppStyles from "./AppStyles";
import BookScreen from "./screens/BookScreen";
import HomeScreen from "./screens/HomeScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ChapterScreen from "./screens/ChapterScreen";
import * as TabScreen from "./screens/TabScreen";
import BrowseScreen from "./screens/BrowseScreen";
import SettingsScreen from "./screens/SettingsScreen";
import AuthorScreen from "./screens/AuthorScreen";
import GenreScreen from "./screens/GenreScreen";
import InviteScreen from './screens/InviteScreen';
import ResetPasswordScreen from "./screens/ResetPasswordScreen";

import "./CoreApp.css";
import MyBooksScreen from "./screens/MyBooksScreen";
import PurchaseBeanScreen from "./screens/PurchaseBeanScreen";

interface LocationState {
  prevPath?: string;
}

function ScrollToTop({ children }: { children: any }) {
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo({
        top: 0,
      });
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
}

const getPathFromRoute = (route: TabScreen.Route) => {
  switch (route) {
    case TabScreen.Route.Home:
      return "/";
    case TabScreen.Route.Browse:
      return "/browse";
    case TabScreen.Route.Profile:
      return "/profile";
    case TabScreen.Route.MyBooks:
      return "/mybooks";
  }
};

const getRouteFromPath = (path: string) => {
  const routes = Object.values(TabScreen.Route);
  for (const route of routes) {
    if (getPathFromRoute(route as TabScreen.Route) === path) {
      return route;
    }
  }
  return null;
};

const BottomTabs = ({}) => {
  const history = useHistory();
  const location = useLocation<LocationState | undefined>();

  // Don't show tabs on reset password
  console.log(location);
  if (location.pathname === "/reset_password" || location.pathname.includes("/i/")) {
    return <></>;
  }

  return (
    <>
      <View
        style={{ height: 50, backgroundColor: AppStyles.pageBackgroundColor }}
      />
      <View
        // @ts-ignore: fixed
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          zIndex: 3,
          flexDirection: "row",
          backgroundColor: "#232323",
        }}
      >
        {Object.values(TabScreen.Route).map((route) => {
          const icon = TabScreen.getIconForRoute(route as TabScreen.Route);
          const pathForRoute = getPathFromRoute(route as TabScreen.Route);
          const prevPath = location.state?.prevPath;

          const isActive = location.pathname === pathForRoute;
          return (
            <Pressable
              key={route}
              style={{
                flex: 1,
                alignItems: "center",
              }}
              onPress={() => history.push(pathForRoute)}
            >
              <Ionicons
                // @ts-ignore: Dynamic outline
                name={isActive ? icon : `${icon}-outline`}
                size={26}
                color={isActive ? "white" : "#505050"}
                style={{
                  paddingVertical: 18,
                }}
              />
            </Pressable>
          );
        })}
      </View>
    </>
  );
};

const CoreApp = () => {
  const { user, userInitialized } = useAuthContext();

  React.useEffect(() => {
    if (userInitialized) {
      logEvent("page_load");
    }
  }, [userInitialized]);

  if (user === undefined) {
    return (
      <View
        style={{ backgroundColor: AppStyles.pageBackgroundColor, flex: 1 }}
      ></View>
    );
  }

  const inviteRoute = (
    <Route exact path="/i/:id">
      {(props) => {
        const inviteId = props.match?.params.id;
        if (inviteId === undefined) {
          return null;
        }
        return <InviteScreen inviter={inviteId} />;
      }}
    </Route>
  );

  if (user === null) {
    return (
      <BrowserRouter>
        <Switch>
          {inviteRoute}
          <Route exact path="/reset_password">
            {(props) => {
              if (props.location.pathname !== "/reset_password") {
                return null;
              }
              return <ResetPasswordScreen />;
            }}
          </Route>
          <Route>
            {(props) => (
              <ProfileScreen navigateToBook={() => {}} navigateToPurchaseBean={() => {}} onGearPress={() => {}} />
            )}
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            {(props) => {
              return (
                <HomeScreen
                  navigateToBook={(bookId) =>
                    props.history.push({
                      pathname: `/book/${bookId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                  navigateToChapter={() => {}}
                  navigateToAuthor={(authorId) =>
                    props.history.push({
                      pathname: `/author/${authorId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                  navigateToGenre={(genreId) =>
                    props.history.push({
                      pathname: `/genre/${genreId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                />
              );
            }}
          </Route>
          <Route path="/book/:id">
            {(props) => {
              const bookId = props.match?.params.id;
              if (bookId === undefined) {
                props.history.replace("/");
                return;
              }

              return (
                <BookScreen
                  bookId={parseInt(bookId)}
                  onBackPress={() => {
                    if (props.location.state === undefined) {
                      props.history.replace("/");
                      return;
                    }

                    props.history.goBack();
                  }}
                  navigateToChapter={(chapterId) =>
                    props.history.push(`/chapter/${chapterId}`)
                  }
                  navigateToAuthor={(authorId) =>
                    props.history.push({
                      pathname: `/author/${authorId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                  navigateToGenre={(genreId) =>
                    props.history.push({
                      pathname: `/genre/${genreId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                />
              );
            }}
          </Route>
          <Route path="/author/:id">
            {(props) => {
              const authorId = props.match?.params.id;
              if (authorId === undefined) {
                props.history.replace("/");
                return;
              }

              return (
                <AuthorScreen
                  authorId={authorId}
                  onBackPress={() => {
                    if (props.location.state === undefined) {
                      props.history.replace("/");
                      return;
                    }

                    props.history.goBack();
                  }}
                  navigateToBook={(bookId) =>
                    props.history.push({
                      pathname: `/book/${bookId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                />
              );
            }}
          </Route>
          <Route path="/genre/:id">
            {(props) => {
              const genreId = props.match?.params.id;
              if (genreId === undefined) {
                props.history.replace("/");
                return;
              }

              return (
                <GenreScreen
                  genreId={genreId}
                  onBackPress={() => {
                    if (props.location.state === undefined) {
                      props.history.replace("/");
                      return;
                    }

                    props.history.goBack();
                  }}
                  navigateToBook={(bookId) =>
                    props.history.push({
                      pathname: `/book/${bookId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                />
              );
            }}
          </Route>
          <Route path="/browse">
            {(props) => (
              <BrowseScreen
                navigateToBook={(bookId) =>
                  props.history.push({
                    pathname: `/book/${bookId}`,
                    state: {
                      prevPath: props.match?.path,
                    },
                  })
                }
                navigateToGenre={(genreId) =>
                  props.history.push({
                    pathname: `/genre/${genreId}`,
                    state: {
                      prevPath: props.match?.path,
                    },
                  })
                }
              />
            )}
          </Route>
          <Route exact path="/mybooks">
            {(props) => {
              return (
                <MyBooksScreen
                  navigateToBook={(bookId) =>
                    props.history.push({
                      pathname: `/book/${bookId}`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                  navigateToSettings={() =>
                    props.history.push({
                      pathname: `/settings`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                  navigateToPurchaseBean={() =>
                    props.history.push({
                      pathname: `/purchase-bean`,
                      state: {
                        prevPath: props.match?.path,
                      },
                    })
                  }
                />
              );
            }}
          </Route>
          <Route exact path="/profile">
            {(props) => (
              <ProfileScreen
                onGearPress={() => props.history.push("/settings")}
                navigateToBook={(bookId) =>
                  props.history.push({
                    pathname: `/book/${bookId}`,
                    state: {
                      prevPath: props.match?.path,
                    },
                  })
                }
                navigateToPurchaseBean={() =>
                  props.history.push({
                    pathname: `/purchase-bean`,
                    state: {
                      prevPath: props.match?.path,
                    },
                  })
                }
              />
            )}
          </Route>
          <Route exact path="/settings">
            {(props) => {
              return (
                <SettingsScreen
                  onBackPress={() => {
                    props.history.replace("/profile");
                  }}
                />
              );
            }}
          </Route>
          <Route exact path="/purchase-bean">
            {(props) => {
              return (
                <PurchaseBeanScreen
                  onBackPress={() => {
                    if (props.location.state === undefined) {
                      props.history.replace("/");
                      return;
                    }

                    props.history.goBack();
                  }}
                />
              );
            }}
          </Route>
        </Switch>
      </ScrollToTop>
      <BottomTabs />
      <Route exact path="/chapter/:id">
        {(props) => {
          const chapterId = props.match?.params.id;
          if (chapterId === undefined) {
            return null;
          }

          return (
            <ChapterScreen
              navigateToBook={(bookId) =>
                props.history.replace({
                  pathname: `/book/${bookId}`,
                  state: {
                    prevPath: props.match?.path,
                  },
                })
              }
              chapterId={parseInt(chapterId)}
              navigateToChapter={(newChapterId) =>
                props.history.push(`/chapter/${newChapterId}`)
              }
              navigateToPurchaseBean={() =>
                props.history.push({
                  pathname: `/purchase-bean`,
                  state: {
                    prevPath: props.match?.path,
                  },
                })
              }
            />
          );
        }}
      </Route>
      <Route exact path="/reset_password">
        {(props) => {
          if (props.location.pathname !== "/reset_password") {
            return null;
          }
          return <ResetPasswordScreen />;
        }}
      </Route>
      {inviteRoute}
    </BrowserRouter>
  );
};

export default CoreApp;
