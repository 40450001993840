import React, { useRef, useState } from "react";
import { Text, ScrollView, View, Dimensions, TouchableOpacity, Animated, FlatList } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { gql, useQuery } from "@apollo/client";

import AppStyles from "../AppStyles";
import { FETCH_VIEWER, FetchViewerData, FETCH_CAROUSELS, CarouselListData } from '../Queries';
import BookThumbnail from "../components/BookThumbnail";
import Shimmer from "../components/Shimmer";
import { calculateBookProgress } from '../TypeHelpers';
import Banner from '../components/Banner';
import { CarouselData } from "../Queries";
import DisplayCarousel from "../components/DisplayCarousel";

const { width } = Dimensions.get("window");
const leftSpacing = 0.2 * width;
const rightSpacing = leftSpacing;
const buttonSpacing = 50;


const HomeScreenTitle = ({ text }: { text: String }) => {
  return (
    <Text
      style={{
        // paddingHorizontal: 24,
        paddingVertical: 24,
        fontSize: 24,
        color: AppStyles.text.mainText.color,
        fontFamily: AppStyles.text.mainText.font,
      }}
    >
      {text}
    </Text>
  );
};

interface AuthorData {
  id: number;
  name: string;
}

interface ChapterData {
  id: number;
  name: string;
}

interface BookData {
  id: number;
  name: string;
  coverImageUrl: string;
  author: AuthorData;
  shortDescription: string;
  description: string;
  chapters: ChapterData[];
}

interface BookListData {
  allBooks: Array<BookData>;
}

const Header = () => {
  return (
    <Text
      style={{
        color: AppStyles.text.mainText.color,
        paddingHorizontal: 24,
        paddingTop: 24,
        fontSize: 24,
      }}
    >
    </Text>
  );
};

const CurrentlyReading = ({
  fetchViewerData,
  navigateToBook,
}: {
  fetchViewerData: FetchViewerData | undefined;
  navigateToBook: (bookId: number) => void;
}) => {
  const scrollRef = useRef<ScrollView>(null);
  const [currentReadingPos, setCurrentReadingPos] = useState<number>(0);
  return (
    <View style={{
      marginBottom: 24, paddingLeft: leftSpacing,
      paddingRight: rightSpacing
    }}>
      <HomeScreenTitle text="Continue Reading" />
      {fetchViewerData?.viewer?.currentlyReadingBooks &&
        fetchViewerData?.viewer?.currentlyReadingBooks.length > (width - leftSpacing - rightSpacing) / 125 && (
          <View>
            {
            currentReadingPos > 0 &&
            <TouchableOpacity
              style={{ position: "absolute", left: -buttonSpacing, top: 75, backgroundColor: "#DDDDDD", borderRadius: 20 }}
              onPress={() => {
                scrollRef.current?.scrollTo({
                  x: currentReadingPos - 200, y: 0,
                  animated: true
                });
              }}>
              <Ionicons name="chevron-back" color="white" size={36} />
            </TouchableOpacity>
            }
            <TouchableOpacity
              style={{ position: "absolute", right: -buttonSpacing, top: 75, backgroundColor: "#DDDDDD", borderRadius: 20 }}
              onPress={() => {
                scrollRef.current?.scrollTo({
                  x: currentReadingPos + 200, y: 0,
                  animated: true
                });
              }}>
              <Ionicons name="chevron-forward" color="white" size={36} />
            </TouchableOpacity>
          </View>
        )
      }
      <ScrollView
        ref={scrollRef}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        onScroll={(e) => setCurrentReadingPos(e.nativeEvent.contentOffset.x)}
      >
        {fetchViewerData?.viewer?.currentlyReadingBooks.map((book, index) => (
          <View style={{ paddingBottom: 20, marginRight: 20 }} key={`continue-reading-book-${index}`}>
            <BookThumbnail
              key={`continue-${index}`}
              book={{
                name: book.name,
                description: book.shortDescription,
                author: book.author.name,
                coverImageUrl: book.coverImageUrl,
              }}
              includeText={false}
              titleBelow={true}
              onPress={() => navigateToBook(book.id)}
            //progress={calculateBookProgress(book)}
            />
          </View>
        ))}
      </ScrollView>
    </View>
  )
};

const HomeScreen = ({
  navigateToBook,
  navigateToAuthor,
  navigateToGenre,
}: {
  navigateToBook: (bookId: number) => void;
  navigateToAuthor: (authorId: number) => void;
  navigateToGenre: (genreId: string) => void;
}) => {
  // const {loading: fetchBooksLoading, data} = useQuery<BookListData>(ALL_BOOKS);
  // const books = data?.allBooks ?? [];

  const {
    data: fetchViewerData,
    loading: fetchViewerLoading,
  } = useQuery<FetchViewerData>(FETCH_VIEWER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const {
    data: carouselBooksData,
    loading: carouselDataLoading,
    refetch: refetchCarousels,
  } = useQuery<CarouselListData>(FETCH_CAROUSELS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    pollInterval:300000,
  });

  if (fetchViewerLoading) {
    return (
      <View style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}>
        <Header />
        <View style={{ flexDirection: "row", padding: 24 }}>
          <View style={{ marginRight: 8, paddingRight: 24 }}>
            <Shimmer width={100} height={150} />
          </View>
          <View style={{ marginRight: 8, paddingRight: 24 }}>
            <Shimmer width={100} height={150} />
          </View>
          <View style={{ marginRight: 8, paddingRight: 24 }}>
            <Shimmer width={100} height={150} />
          </View>
        </View>
      </View>
    );
  }

  const checkCarouselMatch = ( {
    desiredMatch,
    carouselName,
  }: {
    desiredMatch: string;
    carouselName: string;
  }) => {
    for (let idx = 0; idx < Math.min(desiredMatch.length, carouselName.length); idx++) {
      if (desiredMatch[idx] !== carouselName[idx]) {
        return false;
      }
    }
    return carouselName.length >= desiredMatch.length;
  }

  // Fetch Banner Images
  let banner_images = ["book-379", "book-389", "book-180", "book-434", "book-435", "book-98", "free_reads-", "coffee_beans-"];
  // Data
  let DATA = [];
  let carouselToIndex = new Map();
  let carouselOrder = AppStyles.carouselOrder;
  let carouselIndexSet = new Set();
  if (carouselBooksData) {
    let banner_pos = null;
    for (let i = 0; i < carouselBooksData?.carousels.length; ++i) {
      if (carouselBooksData?.carousels[i].name.substring(0, 6) === "Banner") {
        banner_pos = i;
        continue;
      }
      for (const str of carouselOrder) {
        if (checkCarouselMatch({ desiredMatch: str, carouselName: carouselBooksData?.carousels[i].name })) {
          carouselToIndex.set(str, i);
          carouselIndexSet.add(i);
          break;
        }
      }
    }
    if (banner_pos !== null) {
      DATA.push({ type: "banner", id: banner_pos})
    }
    DATA.push({ type: "reading", id: 0 });
    for (const str of carouselOrder) {
      if (carouselToIndex.has(str)) {
        DATA.push({type: "carousel", id: carouselToIndex.get(str)});
      }
    }
    for (let i = 0; i < carouselBooksData?.carousels.length; ++i) {
      if (!carouselIndexSet.has(i) && i !== banner_pos) {
        DATA.push({type: "carousel", id: i });
      }
    }
  }
  const renderItem = ( {
    item
  }: {
    item: any;
  }) => {
    if (item.type === "banner") {
      return (<Banner bannerItems={carouselBooksData?.carousels[item.id].carouselItems} navigateToAuthor={navigateToAuthor} navigateToBook={navigateToBook} navigateToGenre={navigateToGenre}/>);
    } else if (item.type === "reading") {
      if (fetchViewerData?.viewer?.currentlyReadingBooks &&  
        fetchViewerData?.viewer?.currentlyReadingBooks.length > 0) {
        return (
          <CurrentlyReading fetchViewerData={fetchViewerData} navigateToBook={navigateToBook}/>
        );
      }
    } else if (item.type === "carousel") {
      if (carouselBooksData && carouselBooksData?.carousels[item.id].carouselItems.length > 0
            && carouselBooksData?.carousels[item.id].hidden === false) {
        return (<DisplayCarousel carousel={carouselBooksData?.carousels[item.id]} navigateToBook={navigateToBook} navigateToGenre={navigateToGenre}/>);
      }
    }
    return (<View/>);
  };

  return (
      <FlatList
        data={DATA}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
        style={{
          flex: 1,
          backgroundColor: AppStyles.pageBackgroundColor,
        }}
        contentContainerStyle={{
          backgroundColor: AppStyles.pageBackgroundColor,
        }}
        initialNumToRender={4}
        maxToRenderPerBatch={4}
        windowSize={5}
        removeClippedSubviews
        // Disable Sticky Header
        // stickyHeaderIndices={[
        //   fetchBooksData?.viewer &&
        //   fetchBooksData?.viewer?.currentlyReadingBooks.length > 0
        //     ? 1
        //     : 0,
        // ]}
        alwaysBounceVertical={false}
        />
  );
};


const HomeScreenStack = ({
  navigateToChapter,
  navigateToBook,
  navigateToAuthor,
  navigateToGenre,
}: {
  navigateToChapter: (chapterId: number) => void;
  navigateToBook: (bookId: number) => void;
  navigateToAuthor: (authorId: number) => void;
  navigateToGenre: (genreId: string) => void;
}) => {
  return (
    <HomeScreen navigateToBook={navigateToBook} navigateToAuthor={navigateToAuthor} navigateToGenre={navigateToGenre}/>
  );
};

export default HomeScreenStack;

