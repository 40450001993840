import React from "react";
import { Animated, ViewStyle } from "react-native";

const Fade = ({
  visible,
  children,
  style,
}: {
  visible: boolean;
  children: React.ReactNode;
  style?: ViewStyle;
}) => {
  const [fadeAnimation] = React.useState(new Animated.Value(visible ? 1 : 0));

  React.useEffect(() => {
    if (!visible) {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(fadeAnimation, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }).start();
    }
  }, [visible]);

  return (
    <Animated.View style={[{ opacity: fadeAnimation }, style]}>
      {children}
    </Animated.View>
  );
};

export default Fade;
