import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";

import AppStyles from "../AppStyles";
import ProgressBar from './ProgressBar';

interface BookThumbnailType {
  name: string;
  author: string;
  description: string;
  coverImageUrl: string;
  specialDescription?: string;
}

const BookThumbnail = ({
  book,
  includeText = true,
  onPress,
  progress,
  preload,
  titleBelow = false,
  includeAuthor = true,
}: {
  book: BookThumbnailType;
  includeText?: boolean;
  onPress?: () => void;
  progress?: number;
  preload?: boolean;
  titleBelow?: boolean;
  includeAuthor?: boolean;
}) => {
  const bookWidth = 100;
  const bookHeight = 150;

  const [imageLoading, setIsImageLoading] = React.useState(true);

  return (
    <TouchableOpacity
      style={{
        marginRight: 12,
        flexDirection: "row",
        // backgroundColor: "white",
        maxWidth: 600,
        alignItems: "center",
      }}
      onPress={onPress}
    >
      <View>
      <Image
        source={{ uri: book.coverImageUrl }}
        style={{
          width: bookWidth,
          height: bookHeight,
          backgroundColor: imageLoading ? '#333333' : undefined,
        }}
        resizeMode="contain"
        onLoadEnd={() => setIsImageLoading(false)}
      />
      {progress !== undefined && !includeText && (
        <View style={{alignSelf: 'center', marginTop: 16}}>
          <ProgressBar
            percent={progress}
            // width={bookWidth - 10}
            // borderRadius={10}
            // color="white"
            // unfilledColor="#616161"
          />
        </View>
      )}
      {titleBelow && (
        <View style={{alignSelf: 'center', marginTop: 5}}>
          <Text
          style={{
            color: 'white',
            fontWeight: 'bold', width:bookWidth, fontSize: 15
          }} >
          {book.name}
          </Text>
        </View>
      )}
      </View>
      {includeText && (
        <View style={{ flex: 1, padding: 12 }}>
          <Text
            style={{
              color: AppStyles.text.mainText.color,
              fontFamily: AppStyles.text.secondaryText.font,
              fontWeight: "bold",
              fontSize: 14,
            }}
            
          >
            {book.name}
          </Text>
          <Text style={{ color: AppStyles.text.mainText.color, 
                         fontSize: 14,
                         fontFamily: AppStyles.text.secondaryText.font }}>
            {book.author}
          </Text>
          <Text
            style={{
              color: AppStyles.text.mainText.color,
              paddingTop: 8,
              fontSize: 14,
              fontFamily: AppStyles.text.secondaryText.font,
            }}
          >
            {book.description}
          </Text>
          <Text
              style={{
                color: AppStyles.text.mainText.color,
                paddingTop: 8,
                fontFamily: AppStyles.text.secondaryText.font,
              }}
              numberOfLines={progress !== undefined ? 2 : 3}>
              {book.specialDescription}
            </Text>
          {progress !== undefined && (
            <View style={{marginTop: 8}}>
              <ProgressBar
                percent={progress}
                // width={150}
                // borderRadius={10}
                // color="white"
                // unfilledColor="#616161"
              />
            </View>
          )}
        </View>
      )}
    </TouchableOpacity>
  );
};

export default BookThumbnail;
