import React from 'react';
import {Pressable, ScrollView, TextStyle, View, Text} from 'react-native';
import {gql, useQuery, useMutation} from '@apollo/client';
// import {useNavigation, useScrollToTop} from '@react-navigation/native';
import {SafeAreaView, useSafeAreaInsets} from 'react-native-safe-area-context';

import AppStyles from '../AppStyles';
import {FETCH_VIEWER, FetchViewerData} from '../Queries';
import {useAuthContext} from '../context/AuthContext';
import MagicBeanButton from '../components/MagicBeanButton';
import {calculateBookProgress} from '../TypeHelpers';
import BookThumbnail from '../components/BookThumbnail';

const Header = ({
  name,
  navigateToSettings,
  navigateToPurchaseBean,
  hasUserDataLoaded,
  fetchViewerData,
}: {
  name: string;
  navigateToSettings: () => void;
  navigateToPurchaseBean: () => void;
  hasUserDataLoaded: boolean;
  fetchViewerData: FetchViewerData | undefined;
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 12,
        paddingVertical: 8,
      }}>
      {/* <Text
        style={{
          fontWeight: '900',
          fontSize: 24,
          flex: 1,
          height: 45,
          paddingVertical: 8,
        }}>
        {name ?? ' '}
      </Text> */}
      {
        hasUserDataLoaded &&
        fetchViewerData !== undefined && (
          <View style={{alignSelf: 'center'}}>
            <MagicBeanButton viewerData={fetchViewerData} navigateToPurchaseBean={navigateToPurchaseBean}/>
          </View>
        )
      }
    </View>
  );
};

const MyBooksScreen = ({
    navigateToBook,
    navigateToSettings,
    navigateToPurchaseBean
} : {
    navigateToBook: (bookId: number) => void;
    navigateToSettings: () => void;
    navigateToPurchaseBean: () => void;
}) => {
  const {user} = useAuthContext();

  const {
    data: fetchViewerData,
    loading: fetchViewerDataLoading,
    refetch,
    error: fetchViewerError,
  } = useQuery<FetchViewerData>(FETCH_VIEWER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const isFirstRun = React.useRef(true);
//   React.useEffect(() => {
//     if (!user) {
//       return;
//     }

//     if (isFirstRun.current) {
//       isFirstRun.current = false;
//       return;
//     }

//     refetch();
//   }, [user?.viewMature])

  const hasUserDataLoaded = !!fetchViewerData && !!fetchViewerData.viewer;
  const currentlyReadingBooks = fetchViewerData?.viewer?.currentlyReadingBooks;
  console.log("hi");
  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ backgroundColor: AppStyles.pageBackgroundColor }}
    >
      <Header
        name={user?.username ?? ''}
        navigateToSettings={() => navigateToSettings()}
        navigateToPurchaseBean={navigateToPurchaseBean}
        hasUserDataLoaded={hasUserDataLoaded}
        fetchViewerData={fetchViewerData}
      />
      <View key="1" style={{padding: 8}}>
            {currentlyReadingBooks?.map((book, index) => (
              <View style={{marginVertical: 12}} key={`continue-${index}`}>
                <BookThumbnail
                  book={{
                    name: book.name,
                    author: book.author.name,
                    coverImageUrl: book.coverImageUrl,
                    description: book.shortDescription,
                    specialDescription:
                      book.latestChapterRead !== undefined
                        ? `Episode ${book.latestChapterRead.chapterIndex} / ${book.chapters.length}`
                        : ``,
                  }}
                  onPress={() =>
                    navigateToBook(book.id)
                  }
                  progress={calculateBookProgress(book)}
                  includeAuthor={false}
                />
              </View>
            ))}
            {(currentlyReadingBooks == null || currentlyReadingBooks.length === 0) && !fetchViewerDataLoading &&
              <View style={{marginVertical: 20}} key={`empty-books-screen`}>
                <Text style={{'fontSize': AppStyles.tabHeadingsSize, 'color': AppStyles.text.mainText.color}}>
                  Welcome to Stori.
                  {'\n\n'}
                  You will see the books you are currently reading here!
                </Text>
              </View>}
          </View>
    </ScrollView>
  );
};

export default MyBooksScreen;
