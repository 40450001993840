import React, { useRef, useState } from "react";
import { Text, ScrollView, View, Dimensions, TouchableOpacity, Animated, FlatList } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { gql, useQuery } from "@apollo/client";

import AppStyles from "../AppStyles";
import { FETCH_VIEWER, FetchViewerData, FETCH_CAROUSELS, CarouselListData } from '../Queries';
import BookThumbnail from "../components/BookThumbnail";
import Shimmer from "../components/Shimmer";
import { calculateBookProgress } from '../TypeHelpers';
import Banner from '../components/Banner';
import { CarouselData } from "../Queries";
import GenreThumbnail from "./GenreThumbnail";

const { width } = Dimensions.get("window");
const leftSpacing = 0.2 * width;
const rightSpacing = leftSpacing;
const buttonSpacing = 50;

const HomeScreenTitle = ({ text }: { text: String }) => {
    return (
      <Text
        style={{
          // paddingHorizontal: 24,
          paddingVertical: 24,
          fontSize: 24,
          color: AppStyles.text.mainText.color,
          fontFamily: AppStyles.text.mainText.font,
        }}
      >
        {text}
      </Text>
    );
  };

const DisplayCarousel = ({
    carousel,
    navigateToBook,
    navigateToGenre,
}: {
    carousel: CarouselData;
    navigateToBook: (bookId: number) => void;
    navigateToGenre?: (genreId: string) => void;
}) => {
    const scrollRef = useRef<FlatList>(null);
    const [pos, setPos] = useState<number>(0);

    const book_data = Array.from(Array(carousel.carouselItems.length), (x, i) => i);

    const renderBookItem = ({
        item
    }: {
        item: any;
    }) => {
        const book_item = carousel.carouselItems[item];
        if (book_item.book) {
            return (<View style={{ paddingBottom: 20, marginRight: 20 }} key={`book-${item}`}>
                <BookThumbnail
                    book={{
                        description: book_item.book.shortDescription,
                        author: book_item.book.author.name,
                        name: book_item.book.name,
                        coverImageUrl: book_item.book.coverImageUrl,
                    }}
                    onPress={() => {
                        if (book_item.book)
                            navigateToBook(book_item.book.id);
                    }}
                    includeText={false}
                    titleBelow={true}
                />
            </View>);
        } else if (book_item.genre && book_item.imageUrl) {
            return (<View style={{ paddingBottom: 20, marginRight: 20 }} key={`book-${item}`}>
                <GenreThumbnail
                    genre={{
                        name: book_item.genre.displayName,
                        coverImageUrl: book_item.imageUrl.toString(),
                    }}
                    onPress={() => {
                        if (book_item.genre && navigateToGenre)
                            navigateToGenre(book_item.genre.label);
                    }}
                    titleBelow={true}
                />
            </View>);
        } else {
            return (<View style={{ paddingBottom: 20, marginRight: 20 }} key={`book-${item}`}></View>);
        }
    };

    return (
        <View style={{ marginBottom: 24, paddingLeft: leftSpacing - buttonSpacing, paddingRight: rightSpacing - buttonSpacing }}>
            <View style={{ paddingLeft: buttonSpacing, paddingRight: buttonSpacing }}>
                <HomeScreenTitle text={carousel.name} />
                {carousel.carouselItems.length > (width - leftSpacing - rightSpacing) / 125 && (
                    <View>
                        {pos > 0 &&
                            <TouchableOpacity
                                style={{ position: "absolute", left: -buttonSpacing, top: 75, backgroundColor: "#DDDDDD", borderRadius: 20 }}
                                onPress={() => {
                                    scrollRef.current?.scrollToOffset({
                                        offset: Math.max(pos - 200, 0),
                                        animated: true
                                    });
                                }}>
                                <Ionicons name="chevron-back" color="white" size={36} />
                            </TouchableOpacity>
                        }
                        <TouchableOpacity
                            style={{ position: "absolute", right: -buttonSpacing, top: 75, backgroundColor: "#DDDDDD", borderRadius: 20 }}
                            onPress={() => {
                                scrollRef.current?.scrollToOffset({
                                    offset: pos + 200,
                                    animated: true
                                });
                            }}>
                            <Ionicons name="chevron-forward" color="white" size={36} />
                        </TouchableOpacity>
                    </View>
                )
                }
                <FlatList
                    ref={scrollRef}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    style={{ marginVertical: 12, marginRight: 0 }}
                    onScroll={(e) => setPos(e.nativeEvent.contentOffset.x)}
                    data={book_data}
                    renderItem={renderBookItem}
                    keyExtractor={(item) => item.id}
                    initialNumToRender={2}
                    maxToRenderPerBatch={2}
                    removeClippedSubviews
                />
            </View>
        </View>)
};

export default DisplayCarousel;