import React from "react";
import { Pressable, ScrollView, Text, TextInput, View } from "react-native";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import AppStyles from "../AppStyles";
import { useAuthContext } from "../context/AuthContext";

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!, $userId: Int!) {
    resetAndUpdatePassword(token: $token, password: $password, userId: $userId)
  }
`;

interface ResetPasswordInput {
  userId: number;
  password: string;
  token: string;
}

const ResetInput = ({
  label,
  password,
  onChangeText,
}: {
  label: string;
  password?: boolean;
  onChangeText: (text: string) => void;
}) => {
  return (
    <View style={{ marginBottom: 24 }}>
      <View
        style={{
          backgroundColor: "#3c3c3c",
          flex: 1,
          paddingLeft: 4,
          borderRadius: 8,
        }}
      >
        <TextInput
          onChangeText={onChangeText}
          secureTextEntry={password}
          placeholder={label}
          style={{
            // @ts-ignore: web
            outline: "none",
            padding: 16,
            flex: 1,
            color: "white",
            fontSize: 16,
          }}
        />
      </View>
    </View>
  );
};

const Button = ({ onPress }: { onPress: () => void }) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: 8,
        padding: 12,
      }}
    >
      <Text style={{ fontSize: 24 }}>Reset</Text>
    </Pressable>
  );
};

const ResetPasswordScreen = ({}: {}) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const { logout } = useAuthContext();
  console.log(parsed);

  const [password, setPassword] = React.useState<string>();
  const [confirmPassword, setConfirmPassword] = React.useState<string>();

  const [resetPassword, { data }] = useMutation<boolean, ResetPasswordInput>(
    RESET_PASSWORD
  );

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{
        padding: 48,
      }}
    >
      <Text
        style={{
          fontSize: 36,
          color: AppStyles.text.mainText.color,
          marginBottom: 36,
        }}
      >
        Reset Password
      </Text>
      <ResetInput
        label="Password"
        onChangeText={(text) => setPassword(text)}
        password
      />
      <ResetInput
        label="Confirm Password"
        onChangeText={(text) => setConfirmPassword(text)}
        password
      />
      <Button
        onPress={() => {
          if (password !== confirmPassword) {
            console.log("Different passwords");
            return;
          }

          resetPassword({
            variables: {
              userId: parseInt(parsed.userid as string),
              token: parsed.token as string,
              password: password as string,
            },
          })
            .then(() => logout())
            .then(() => (window.location.href = "/"));
        }}
      />
    </ScrollView>
  );
};

export default ResetPasswordScreen;
