import React, { ReactNode } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

type ReaderPreferencesContextType = {
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
  headerFontSize: number;
  bodyFontSize: number;
  setFontFamilyIndex: (index: number) => void;
  fontFamily: string;
  activeFont: Font;
  activeReaderTheme: ReaderTheme;
  setReaderThemeIndex: (index: number) => void;
};

const ReaderPreferencesContext = React.createContext<
  ReaderPreferencesContextType | undefined
>(undefined);

const useReaderPreferencesContext = (): ReaderPreferencesContextType => {
  const value = React.useContext<ReaderPreferencesContextType | undefined>(
    ReaderPreferencesContext
  );
  if (value === undefined) {
    throw new Error("Did you use a provider?");
  }
  return value;
};

interface ReaderTheme {
  textColor: string;
  backgroundColor: string;
  menuTextColor: string;
  menuBackgroundColor: string;
  borderColor: string;
}

const textSizes = [14, 16, 18, 20, 22, 24];
const headerSizes = [24, 28, 30, 32, 34, 36];
const defaultTextSizeIndex = 2;
export const readerThemes: Array<ReaderTheme> = [
  // White
  {
    textColor: "#000000",
    backgroundColor: "#FFFFFF",
    menuTextColor: "#000000",
    menuBackgroundColor: "#FFFFFF",
    borderColor: "#d1d1d1",
  },
  // Sepia
  {
    textColor: "#000000",
    backgroundColor: "#FBF0D9",
    menuTextColor: "#000000",
    menuBackgroundColor: "#FFFFFF",
    borderColor: "#d1d1d1",
  },
  // Dark
  {
    textColor: "#d0d0d0",
    backgroundColor: "#000000",
    menuTextColor: "#FFFFFF",
    menuBackgroundColor: "#212121",
    borderColor: "#757575",
  },
];

const TEXT_SIZE_INDEX_KEY = "@text_size_index";
const FONT_FAMILY_INDEX_KEY = "@font_family_index";
const READER_THEME_INDEX_KEY = "@reader_theme_index";

export enum Font {
  OpenSans = "OpenSans",
  Vollkorn = "Vollkorn",
  PTSerif = "PTSerif",
}

export const getFontFamily = (font: Font) => {
  switch (font) {
    case Font.OpenSans:
      return "OpenSans_400Regular";
    case Font.Vollkorn:
      return "Vollkorn_400Regular";
    case Font.PTSerif:
      return "PTSerif_400Regular";
  }
};

const ReaderPreferencesProvider = ({ children }: { children: ReactNode }) => {
  const [textSizeIndex, setTextSizeIndex] = React.useState<number | null>(null);
  const [fontFamilyIndex, setFontFamilyIndex] = React.useState<number | null>(
    null
  );
  const [readerThemeIndex, setReaderThemeIndex] = React.useState<number | null>(
    null
  );

  React.useEffect(() => {
    AsyncStorage.multiGet([
      TEXT_SIZE_INDEX_KEY,
      FONT_FAMILY_INDEX_KEY,
      READER_THEME_INDEX_KEY,
    ]).then((data) => {
      if (data.length >= 1) {
        const textSizeData = data[0];
        const textSizeIndex = textSizeData[1];
        const parsedIndex =
          textSizeIndex !== null ? parseInt(textSizeIndex) : 1;
        setTextSizeIndex(!isNaN(parsedIndex) ? parsedIndex : 1);
      }

      if (data.length >= 2) {
        const fontFamilyData = data[1];
        const fontFamilyIndex = fontFamilyData[1];
        const parsedIndex =
          fontFamilyIndex !== null
            ? parseInt(fontFamilyIndex)
            : defaultTextSizeIndex;
        setFontFamilyIndex(
          !isNaN(parsedIndex) ? parsedIndex : defaultTextSizeIndex
        );
      }

      if (data.length >= 3) {
        const readerThemeData = data[2];
        const readerThemeIndex = readerThemeData[1];
        const parsedIndex =
          readerThemeIndex !== null ? parseInt(readerThemeIndex) : 1;
        setReaderThemeIndex(!isNaN(parsedIndex) ? parsedIndex : 1);
      }
    });
  }, []);

  React.useEffect(() => {
    if (textSizeIndex === null) {
      return;
    }
    AsyncStorage.setItem(TEXT_SIZE_INDEX_KEY, textSizeIndex.toString());
  }, [textSizeIndex]);

  React.useEffect(() => {
    if (fontFamilyIndex === null) {
      return;
    }
    AsyncStorage.setItem(FONT_FAMILY_INDEX_KEY, fontFamilyIndex.toString());
  }, [fontFamilyIndex]);

  React.useEffect(() => {
    if (readerThemeIndex === null) {
      return;
    }
    AsyncStorage.setItem(READER_THEME_INDEX_KEY, readerThemeIndex.toString());
  }, [readerThemeIndex]);

  const increaseFontSize = () => {
    if (textSizeIndex === null) {
      return;
    }

    if (textSizeIndex < textSizes.length - 1) {
      setTextSizeIndex(textSizeIndex + 1);
    }
  };

  const decreaseFontSize = () => {
    if (textSizeIndex === null) {
      return;
    }

    if (textSizeIndex > 0) {
      setTextSizeIndex(textSizeIndex - 1);
    }
  };

  const activeFont = Object.values(Font)[fontFamilyIndex ?? 1] as Font;

  return (
    <ReaderPreferencesContext.Provider
      value={{
        increaseFontSize,
        decreaseFontSize,
        setFontFamilyIndex,
        headerFontSize: headerSizes[textSizeIndex ?? defaultTextSizeIndex],
        bodyFontSize: textSizes[textSizeIndex ?? defaultTextSizeIndex],
        fontFamily: getFontFamily(activeFont),
        activeFont,
        activeReaderTheme: readerThemes[readerThemeIndex ?? 1],
        setReaderThemeIndex,
      }}
    >
      {children}
    </ReaderPreferencesContext.Provider>
  );
};

export { ReaderPreferencesProvider, useReaderPreferencesContext };
