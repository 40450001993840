import React from "react";
import {
  Linking,
  Image,
  Text,
  Platform,
  Pressable,
  ScrollView,
  View,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { gql, useQuery, useMutation } from "@apollo/client";

import { BOOK_QUERY, BookData } from "../Queries";
import BookThumbnail from "../components/BookThumbnail";
import Shimmer from "../components/Shimmer";
import HomeScreenTitle from "../components/HomeScreenTitle";

import AppStyles from "../AppStyles";

export const FETCH_GENRE = gql`
  query GenreByLabel($label: String!) {
    genreByLabel(label: $label) {
      id
      label
      displayName
      books {
        ${BOOK_QUERY}
      }
    }
  }
`;

interface GenreData {
  id: string;
  label: string;
  displayName: string;
  books: Array<BookData>;
}

interface FetchGenreData {
  genreByLabel: GenreData;
}

interface FetchGenreInput {
  label: string;
}

const GenreScreen = ({
  genreId,
  onBackPress,
  navigateToBook,
}: {
  genreId: string;
  onBackPress: () => void;
  navigateToBook: (bookId: number) => void;
}) => {
  const { data } = useQuery<FetchGenreData, FetchGenreInput>(FETCH_GENRE, {
    variables: { label: genreId },
  });

  return (
    <ScrollView
      style={{ backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <Pressable
        style={{ position: "absolute", left: 18, top: 18, zIndex: 1 }}
        onPress={onBackPress}
      >
        <Ionicons name="chevron-back" color="white" size={36} />
      </Pressable>
      <View style={{ marginTop: 24, alignItems: "center", paddingTop: 24 }}>
        {data?.genreByLabel ? (
          <Text style={{ color: AppStyles.text.mainText.color, fontSize: 24,
                          fontFamily: AppStyles.text.secondaryText.font, }}>
            {data?.genreByLabel.displayName}
          </Text>
        ) : (
          <Shimmer height={30} width={100} borderRadius={12} />
        )}
      </View>
      <View style={{ padding: 24 }}>
        {data?.genreByLabel ? (
          <>
            <View style={{ paddingVertical: 12 }}>
              <HomeScreenTitle text="Books" />
            </View>
            {data?.genreByLabel.books.map((book) => {
              return (
                <View style={{ marginTop: 12 }} key={book.id}>
                  <BookThumbnail
                    key={`genre-book-${book.id}`}
                    book={{
                      name: book.name,
                      description: book.shortDescription,
                      author: book.authors[0].name,
                      coverImageUrl: book.coverImageUrl,
                    }}
                    onPress={() => navigateToBook(book.id)}
                  />
                </View>
              );
            })}
            {data.genreByLabel.books.length === 0 && (
              <Text
                style={{ color: AppStyles.text.mainText.color, fontSize: 16 }}
              >
                Nothing here...yet
              </Text>
            )}
          </>
        ) : (
          <View style={{ marginRight: 8, paddingRight: 24, marginTop: 48 }}>
            <Shimmer width={100} height={150} />
          </View>
        )}
      </View>
    </ScrollView>
  );
};

export default GenreScreen;
