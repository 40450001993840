import React from 'react';
import {PixelRatio} from 'react-native';
import {Pressable, View} from 'react-native';
import { Image, Text } from "react-native";
import {useQuery} from '@apollo/client';

interface GenreThumbnailType {
  name: string;
  coverImageUrl: string;
}

const GenreThumbnail = ({
  genre,
  onPress,
  titleBelow = false,
}: {
  genre: GenreThumbnailType;
  onPress?: () => void;
  titleBelow?: boolean;
}) => {
  const genreWidth = 100;
  const genreHeight = 150;

  const [imageLoading, setIsImageLoading] = React.useState(true);

  return (
    <View>
      <Pressable
        style={{
          flexDirection: 'row',
        }}
        onPress={onPress}>
        <View>
          <Image
            source={{uri: genre.coverImageUrl != null ? 
                        genre.coverImageUrl : 
                      'https://res.cloudinary.com/stori/image/upload/v1643098767/stori_images/default_cover_tkof4k.png',
                    }}
            style={{
              width: genreWidth,
              height: genreHeight,
              backgroundColor: imageLoading ? '#333333' : undefined,
            }}
            resizeMode="contain"
            onLoadEnd={() => setIsImageLoading(false)}
          />
          {titleBelow && (
            <View style={{alignSelf: 'center', marginTop: 5}}>
              <Text style={{
                  color: 'white',
                fontWeight: 'bold', width:genreWidth, fontSize: 15
              }}>
                {genre.name}
              </Text>
            </View>
          )}
        </View>
      </Pressable>
    </View>
  );
};

export default GenreThumbnail;
