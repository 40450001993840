import React from 'react';
import {
  Pressable,
  View,
  ScrollView,
  PixelRatio,
  Dimensions,
  Text,
  Image,
  TouchableOpacity
} from 'react-native';
import { Ionicons } from "@expo/vector-icons";
import { useQuery } from '@apollo/client';

import { FETCH_BOOK } from '../screens/BookScreen';

// import BuyBeansModal from '../components/BuyBeansModal';

import {
  CarouselData
} from '../Queries';

const { width } = Dimensions.get("window");
const height = width * 0.3;
const slideShowDotSize = Math.min(height * 0.03, 10)
const buttonSpacing = 50;

interface BookBannerType {
  id: number;
  name: string;
  author: string;
  description?: string;
  coverImageUrl: string;
}

const BannerSlide = ({
  book,
  image,
  isUrl = false,
  onPress,
  imageWidth,
  imageHeight
}: {
  book?: BookBannerType;
  image?: string;
  isUrl?: boolean;
  onPress?: () => void;
  imageWidth: number;
  imageHeight: number;
}) => {
  const bannerWidth = width;
  const bannerHeight = height;

  const [imageLoading, setIsImageLoading] = React.useState(true);
  if (!isUrl) {
    return (
      <View>
        <Pressable
          style={{
            flexDirection: 'row',
            width: imageWidth,
            height: imageHeight
          }}
          onPress={onPress}>
          <View>
            {
              image == "coffee_beans-" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1653250802/banner_images/coffee_beans-_qyp08h.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "free_reads-" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1653250801/banner_images/free_reads-_u2jjxy.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "author-21" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1653250800/banner_images/author-21_isobnv.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "book-379" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1653751627/banner_images/book-379_np40zj.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "book-389" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1653751627/banner_images/book-389_f3l4iz.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "book-98" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1653751626/banner_images/book-98_xe2orc.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "book-180" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1654836365/banner_images/book-180_tb51be.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "book-434" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1654836365/banner_images/book-434_lnvefc.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  //backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
            {
              image == "book-435" && (
              <Image
                source={{uri: 'https://res.cloudinary.com/stori/image/upload/v1654836366/banner_images/book-435_pilxls.jpg'}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  //backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
              )
            }
          </View>
        </Pressable>
      </View>);
  } else {
    return (
      <View>
        <Pressable
          style={{
            flexDirection: 'row',
            width: imageWidth,
            height: imageHeight
          }}
          onPress={onPress}>
          <View>
            {
              <Image
                source={{uri: image}}
                style={{
                  width: bannerWidth,
                  height: bannerHeight,
                  // backgroundColor: imageLoading ? '#333333' : undefined,
                }}
                resizeMode="contain"
                onLoadEnd={() => setIsImageLoading(false)}
              />
            }
          </View>
        </Pressable>
      </View>);
  }
}

const mod = (n: number, m: number) => {
  return ((n % m) + m) % m;
};

const Banner = ({
  images,
  bannerItems,
  navigateToAuthor,
  navigateToBook,
  navigateToGenre,
  noLink = false,
}: {
  images?: any[];
  bannerItems?: any[];
  navigateToAuthor: (authorId: number) => void;
  navigateToBook: (bookId: number) => void;
  navigateToGenre?: (genreId: string) => void;
  noLink?: boolean,
}) => {
  const { client } = useQuery(FETCH_BOOK, {
    skip: true,
  });

  const [activeTab, setActiveTab] = React.useState(0);

  const [beansModalVisible, setBeansModalVisible] = React.useState(false);

  let onPressFunctions: (() => void)[] = []
  if (bannerItems) {
    for (const im of bannerItems) {
      const types = im.type;
      if (types == "coffee_bean") {
        onPressFunctions.push(() => setBeansModalVisible(true))
      } else if (types == "genre" && navigateToGenre) {
        onPressFunctions.push(() => navigateToGenre(im.genre.label))
      } else if (types == "book") {
        onPressFunctions.push(() => navigateToBook(im.book.id))
      } else {
        onPressFunctions.push(() => {})
      }
    }
  } else if (images) {
    for (const im of images) {
      onPressFunctions.push(() => {})
    }
  }
  let numItems = 0;
  if (bannerItems) {
    numItems = bannerItems.length;
  } else if (images) {
    numItems = images.length;
  }

  return (
    <>
    <View style={{ marginBottom: 24, width, height }}>
      <View
        style={{ width, height }}>
        {images && images.map(
          (image, index) =>
          index == activeTab && (
            <View style={{ paddingBottom: 20, width, height }} key={`banner-${index}`}>
              <BannerSlide
                image={image}
                onPress={onPressFunctions[index]}
                imageHeight={height}
                imageWidth = {(width - 2 * height) / 2}
              />
            </View>
          ),
        )}
        {bannerItems && bannerItems.map(
          (image, index) =>
          index == activeTab && (
            <View style={{ paddingBottom: 20, width, height }} key={`banner-${index}`}>
              <BannerSlide
                image={image.imageUrl}
                onPress={onPressFunctions[index]}
                imageHeight={height}
                imageWidth = {(width - 2 * height) / 2}
                isUrl = {true}
              />
            </View>
          ),
        )}
      </View>
      <TouchableOpacity
        style={{ position: "absolute", left: (width - 2 * height) / 2 - buttonSpacing, top: height / 2 }}
        onPress={() => {
          setActiveTab(mod(activeTab - 1, numItems));
        }}>
        <Ionicons name="chevron-back" color="white" size={36} />
      </TouchableOpacity>
      <TouchableOpacity
        style={{ position: "absolute", right: (width - 2 * height) / 2 - buttonSpacing, top: height / 2 }}
        onPress={() => {
          setActiveTab(mod(activeTab + 1, numItems));
        }}>
        <Ionicons name="chevron-forward" color="white" size={36} />
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', position: 'absolute', bottom: 0, alignSelf: 'center' }}>
        {
          images && images.map((image, index) => (
            <Text style={index == activeTab ? { color: 'white', fontSize: slideShowDotSize } : { color: '#888', fontSize: slideShowDotSize }}>⬤ </Text>
          ))
        }
        {
          bannerItems && bannerItems.map((image, index) => (
            <Text style={index == activeTab ? { color: 'white', fontSize: slideShowDotSize } : { color: '#888', fontSize: slideShowDotSize }}>⬤ </Text>
          ))
        }
      </View>
      </View>
    {/* <BuyBeansModal
        isVisible={beansModalVisible}
        hideModal={() => setBeansModalVisible(false)}
    /> */}
    </>
  );
};

export default Banner;
