import React, { ReactNode } from "react";
import { useFonts, OpenSans_400Regular } from '@expo-google-fonts/open-sans';
import { PTSerif_400Regular } from '@expo-google-fonts/pt-serif';
import { Vollkorn_400Regular } from '@expo-google-fonts/vollkorn';


const FontContext = React.createContext(undefined);

const FontContextProvider = ({ children }: { children: ReactNode }) => {
  useFonts({
    OpenSans_400Regular,
    PTSerif_400Regular,
    Vollkorn_400Regular,
  });

  return (
    <FontContext.Provider value={undefined}>
      {children}
    </FontContext.Provider>
  );
};

export { FontContextProvider };
