import React, { useEffect, useState } from "react";
import { Pressable, Text, TextInput, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { gql, useQuery } from "@apollo/client";

import AppStyles from "../AppStyles";
import BookThumbnail from "../components/BookThumbnail";

const SEARCH_BOOKS = gql`
  query SearchBooksQuery($query: String!) {
    searchBooks(query: $query) {
      id
      name
      description
      shortDescription
      coverImageUrl
      authors {
        name
      }
    }
  }
`;

interface Author {
  id: number;
  name: string;
}

interface BookData {
  id: number;
  coverImageUrl: string;
  name: string;
  description: string;
  shortDescription: string;
  authors: Array<Author>;
}

interface FetchBookData {
  searchBooks: Array<BookData>;
}

const ALL_GENRES = gql`
  query FetchGenres {
    genres {
      id
      label
      displayName
    }
  }
`;

interface GenreData {
  id: number;
  label: string;
  displayName: string;
}

interface FetchGenreData {
  genres: Array<GenreData>;
}

const BrowseScreen = ({
  navigateToBook,
  navigateToGenre,
}: {
  navigateToBook: (bookId: number) => void;
  navigateToGenre: (genreId: string) => void;
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<Array<BookData>>([]);

  const { data: fetchBookData } = useQuery<FetchBookData>(SEARCH_BOOKS, {
    variables: { query: searchText },
  });

  const { data: fetchGenresData } = useQuery<FetchGenreData>(ALL_GENRES);

  useEffect(() => {
    if (fetchBookData !== undefined) {
      setSearchResults(fetchBookData.searchBooks);
    }
  }, [fetchBookData]);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: AppStyles.pageBackgroundColor,
        paddingHorizontal: 24,
        paddingVertical: 36,
      }}
    >
      <Text
        style={{
          color: AppStyles.text.mainText.color,
          fontSize: 24,
          fontFamily: AppStyles.text.secondaryText.font,
        }}
      >
        Browse by genre
      </Text>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 24,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#3c3c3c",
            flex: 1,
            paddingLeft: 12,
            borderRadius: 8,
          }}
        >
          <Ionicons
            name="search"
            color="#828282"
            size={18}
            style={{ marginRight: 8 }}
          />
          <TextInput
            placeholder="Search"
            style={{
              // @ts-ignore: web
              outline: "none",
              padding: 16,
              flex: 1,
              color: "white",
              fontSize: 16,
              fontFamily: AppStyles.text.secondaryText.font,
            }}
            value={searchText}
            onChangeText={(text) => setSearchText(text)}
          />
        </View>
      </View>
      {searchResults.map((book, index) => (
        <BookThumbnail
          key={`search-${index}`}
          book={{
            name: book.name,
            description: book.shortDescription,
            author: book.authors[0].name,
            coverImageUrl: book.coverImageUrl,
          }}
          onPress={() => {
            navigateToBook(book.id);
          }}
        />
      ))}
      {fetchGenresData !== undefined && searchText === "" && (
        <Pressable style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {fetchGenresData.genres
            .slice()
            .sort((genreA, genreB) => {
              const compare = genreA.displayName.localeCompare(
                genreB.displayName
              );
              return compare;
            })
            .map((genre) => {
              return (
                <Pressable
                  key={genre.label}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#3c3c3c",
                    padding: 8,
                    borderRadius: 8,
                    marginRight: 8,
                    marginBottom: 8,
                  }}
                  onPress={() => navigateToGenre(genre.label)}
                >
                  <Text
                    style={{
                      color: AppStyles.text.mainText.color,
                      fontSize: 16,
                      fontFamily: AppStyles.text.secondaryText.font,
                    }}
                  >
                    {genre.displayName}
                  </Text>
                </Pressable>
              );
            })}
        </Pressable>
      )}
    </View>
  );
};

export default BrowseScreen;
