export enum Route {
  Home = "Home",
  Browse = "Browse",
  MyBooks = "MyBooks",
  Profile = "Profile",
}

export const getIconForRoute = (route: Route) => {
  switch (route) {
    case Route.Home:
      return "home";
    case Route.Browse:
      return "grid";
    case Route.MyBooks:
      return "book"
    case Route.Profile:
      return "person-circle";
  }
};