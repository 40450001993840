import React from "react";
import { Pressable, ScrollView, Text, TextInput, View } from "react-native";
import { gql, useMutation } from "@apollo/client";
import { useLottie } from "lottie-react";
import spinnerAnimation from "../animations/spinner.json";

import AppStyles from "../AppStyles";

const SEND_INVITE = gql`
  mutation SendInvite($username: String!, $targetEmail: String!) {
    sendInvite(username: $username, targetEmail: $targetEmail)
  }
`;

interface SendInviteInput {
  username: string;
  targetEmail: string;
}

const InviteInput = ({
  label,
  onChangeText,
}: {
  label: string;
  onChangeText: (text: string) => void;
}) => {
  return (
    <View style={{ marginBottom: 24, width: 400, maxWidth: "80%" }}>
      <View
        style={{
          backgroundColor: "#3c3c3c",
          flex: 1,
          paddingLeft: 4,
          borderRadius: 8,
        }}
      >
        <TextInput
          onChangeText={onChangeText}
          placeholder={label}
          style={{
            // @ts-ignore: web
            outline: "none",
            padding: 16,
            flex: 1,
            color: "white",
            fontSize: 16,
          }}
        />
      </View>
    </View>
  );
};

const Loader = () => {
  const options = {
    animationData: spinnerAnimation,
    loop: true,
    autoplay: true,
  };

  const { View: LoaderView } = useLottie(options);
  return LoaderView;
};

const Button = ({
  onPress,
  loading,
}: {
  onPress: () => void;
  loading?: boolean;
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: 8,
        width: 400,
        maxWidth: "80%",
      }}
    >
      {loading ? (
        <View
          style={{
            padding: 4,
            height: 42,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </View>
      ) : (
        <Text style={{ fontSize: 16, padding: 12 }}>Send Invite</Text>
      )}
    </Pressable>
  );
};

const InviteScreen = ({ inviter }: { inviter: string }) => {
  const [email, setEmail] = React.useState("");

  const [inviteUser, { loading, data: inviteUserResponse }] = useMutation<
    boolean,
    SendInviteInput
  >(SEND_INVITE);

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{
        paddingVertical: 48,
        paddingHorizontal: 12,
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontSize: 36,
          color: AppStyles.text.mainText.color,
          marginBottom: 12,
          alignItems: "center",
        }}
      >
        Join Stori
      </Text>
      <Text
        style={{
          fontSize: 24,
          color: AppStyles.text.secondaryText.color,
          marginBottom: 36,
          alignItems: "center",
        }}
      >
        as a guest of {inviter}
      </Text>
      <InviteInput label="Email" onChangeText={(text) => setEmail(text)} />
      <Button
        loading={loading}
        onPress={() => {
          if (email.length <= 0) {
            return;
          }

          inviteUser({
            variables: {
              username: inviter,
              targetEmail: email,
            },
          });
          // resetPassword({
          //   variables: {
          //     userId: parseInt(parsed.userid as string),
          //     token: parsed.token as string,
          //     password: password as string,
          //   },
          // })
          //   .then(() => logout())
          //   .then(() => (window.location.href = "/"));
        }}
      />
      {inviteUserResponse && (
        <Text
          style={{
            color: AppStyles.text.mainText.color,
            textAlign: "center",
            fontSize: 16,
            marginTop: 24,
          }}
        >
          Invite Sent!
        </Text>
      )}
      <View style={{ height: 100 }}></View>
    </ScrollView>
  );
};

export default InviteScreen;
