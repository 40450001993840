import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Text, Pressable, View } from "react-native";

import AppStyles from "../AppStyles";
import { useAuthContext } from "../context/AuthContext";
import { gql, useMutation, useQuery } from "@apollo/client";
import {STRIPE_SESSION_QUERY, StripeBeanPurchaseSession} from "../Queries";

const BORDER_STYLE = {
  borderBottomWidth: 1,
  borderBottomColor: "#283139",
};

const RESET_PURCHASES = gql`
  mutation ResetPurchases {
    resetPurchasesDebug {
      id
    }
  }
`;

const SettingsRow = ({
  text,
  onPress,
}: {
  text: string;
  onPress: () => void;
}) => {
  return (
    <Pressable
      style={{
        ...BORDER_STYLE,
        padding: 24,
      }}
      onPress={onPress}
    >
      <Text style={{ color: AppStyles.text.mainText.color, fontSize: 18, 
                      fontFamily: AppStyles.text.secondaryText.font, }}>
        {text}
      </Text>
    </Pressable>
  );
};

interface BuyBeansData {
  buyBeansStripe: StripeBeanPurchaseSession;
}

interface BuyBeansInput {
  quantity: number;
  origin: string;
}

const BUY_BEANS_STRIPE = gql`
  mutation BuyBeansStripe($quantity: Int!, $origin: String!) {
    buyBeansStripe(quantity: $quantity, origin: $origin) {
      ${STRIPE_SESSION_QUERY}
    }
  }
`;

const PurchaseBeanScreen = ({ onBackPress }: { onBackPress: () => void }) => {
  const { logout } = useAuthContext();

  const [resetPurchases] = useMutation(RESET_PURCHASES);

  const resetPurchaseAndReload = async () => {
    await resetPurchases();
    location.reload();
  };

  const [buyBeansStripe, { loading: buyBeansLoading }] = useMutation<
    BuyBeansData,
    BuyBeansInput
  >(BUY_BEANS_STRIPE);

  async function initiatePurchase(quantity: number) {
    console.log("bean button");
    console.log(window.location.origin);
    if (buyBeansLoading) {
      return;
    }

    const session = await buyBeansStripe({
      variables: {
        quantity: quantity,
        origin: window.location.origin,
      }}
    );
    console.log(session.data);
    window.location.replace(session!.data!.buyBeansStripe.url);
  }

  return (
    <View
      style={{
        flex: 1,
        zIndex: 2,
        backgroundColor: AppStyles.pageBackgroundColor,
      }}
    >
      <Pressable
        style={{
          ...BORDER_STYLE,
          flexDirection: "row",
          padding: 12,
          alignItems: "center",
        }}
        onPress={onBackPress}
      >
        <Ionicons name="chevron-back" color="white" size={24} />
        <Text
          style={{
            color: AppStyles.text.mainText.color,
            fontSize: 24,
            fontWeight: "bold",
            marginLeft: 8,
            fontFamily: AppStyles.text.secondaryText.font,
          }}
        >
          Purchase Beans
        </Text>
      </Pressable>
      <SettingsRow
        text="Buy 20 Beans - $0.99"
        onPress={async () => {
          await initiatePurchase(20);
        }}
      />
      <SettingsRow
        text="Buy 60 Beans - $2.99"
        onPress={async () => {
          await initiatePurchase(60);
        }}
      />
      <SettingsRow
        text="Buy 115 Beans - $4.79"
        onPress={async () => {
          await initiatePurchase(115);
        }}
      />
      <SettingsRow
        text="Buy 200 Beans - $7.99"
        onPress={async () => {
          await initiatePurchase(200);
        }}
      />
      <SettingsRow
        text="Buy 300 Beans - $9.99"
        onPress={async () => {
          await initiatePurchase(300);
        }}
      />
    </View>
  );
};

export default PurchaseBeanScreen;
