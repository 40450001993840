import React from "react";
import { Dimensions, Pressable, ScrollView, Text, View } from "react-native";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Ionicons } from "@expo/vector-icons";
import ReactMarkdown from "react-markdown";

import {
  Font,
  getFontFamily,
  useReaderPreferencesContext,
  readerThemes,
} from "../context/ReaderPreferencesContext";
import { logEvent } from "../Logging";
import { FETCH_VIEWER, FetchViewerData } from "./ProfileScreen";
import Fade from "../components/Fade";
import MagicBeanButton from "../components/MagicBeanButton";
import ProgressBar from "../components/ProgressBar";
import Shimmer from "../components/Shimmer";

const BOOK_QUERY = `
book {
  id
  name
  chapters {
    id
    chapterIndex
  }
  unlockableByReading
}
`;

const CHAPTER_QUERY = `
id
chapterIndex
name
text
isComplete
percentComplete
beanPrice
purchased
requiresPurchase
unlockedByCoupon
unlockedByReading
${BOOK_QUERY}
`;

const FETCH_CHAPTER = gql`
  query FetchChapterById($id: Int!) {
    chapterById(id: $id) {
      ${CHAPTER_QUERY}
    }
  }
`;

interface BookChapterData {
  id: number;
  chapterIndex: number;
}

interface BookData {
  id: number;
  name: string;
  chapters: Array<BookChapterData>;
  unlockableByReading: boolean;
}

interface ChapterData {
  id: number;
  chapterIndex: number;
  name: string;
  text: string;
  percentComplete: number;
  isComplete: boolean;
  beanPrice: number;
  purchased: boolean;
  requiresPurchase: boolean;
  unlockedByCoupon: boolean;
  unlockedByReading: boolean;
  book: BookData;
}

interface FetchChapterData {
  chapterById: ChapterData;
}

const UPDATE_READING_PROGRESS = gql`
  mutation UpdatingReadingProgress(
    $chapterId: Int!
    $isComplete: Boolean!
    $floatPercentComplete: Float!
  ) {
    updateReadingProgress(
      chapterId: $chapterId
      isComplete: $isComplete
      floatPercentComplete: $floatPercentComplete
    ) {
      ${CHAPTER_QUERY}
    }
  }
`;

interface UpdateReadingProgressChapterData {
  isComplete: boolean;
  percentComplete: number;
}

interface UpdateReadingProgressData {
  updateReadingProgress: UpdateReadingProgressChapterData;
}

interface UpdateReadingProgressInput {
  chapterId: number;
  isComplete: boolean;
  floatPercentComplete: number;
}

const PURCHASE_CHAPTER = gql`
  mutation PurchaseChapter($chapterId: Int!) {
    purchaseChapter(chapterId: $chapterId) {
      chapter {
        ${CHAPTER_QUERY}
      }
      user {
        id
        beans
      }
    }
  }
`;

interface PurchaseChapterChapterUserData {
  id: number;
  beans: number;
}

interface PurchaseChapterChapterData {
  chapter: ChapterData;
  user: PurchaseChapterChapterUserData;
}

interface PurchaseChapterData {
  purchaseChapter: PurchaseChapterChapterData;
}

interface PurchaseChapterInput {
  chapterId: number;
}

const iconSize = 26;

const getMarginTopForFont = (font: Font) => {
  switch (font) {
    case Font.OpenSans:
      return 1;
    case Font.PTSerif:
      return 0;
    case Font.Vollkorn:
      return 2;
  }
};

const getMarginTopForLabel = (font: Font) => {
  switch (font) {
    case Font.OpenSans:
    case Font.PTSerif:
      return 6;
    case Font.Vollkorn:
      return 3;
  }
};

const FontSelector = ({
  font,
  selected,
  onPress,
}: {
  font: Font;
  selected: boolean;
  onPress: () => void;
}) => {
  const { activeReaderTheme } = useReaderPreferencesContext();
  return (
    <Pressable
      style={{ alignItems: "center", marginHorizontal: 12 }}
      onPress={onPress}
    >
      <Text
        style={{
          fontFamily: getFontFamily(font),
          marginTop: getMarginTopForFont(font),
          fontSize: 20,
          textDecorationLine: selected ? "underline" : "none",
          color: activeReaderTheme.menuTextColor,
        }}
      >
        Aa
      </Text>
      <Text
        style={{
          marginTop: getMarginTopForLabel(font),
          color: activeReaderTheme.menuTextColor,
        }}
      >
        {font}
      </Text>
    </Pressable>
  );
};

const LoadingScreen = ({ visible }: { visible: boolean }) => {
  const { activeReaderTheme } = useReaderPreferencesContext();

  const HEIGHT = 10;

  const textRow = (
    <View style={{ marginBottom: 5 }}>
      <Shimmer width={Dimensions.get("window").width * 0.8} height={HEIGHT} />
    </View>
  );

  return (
    <View
      style={{
        backgroundColor: activeReaderTheme.backgroundColor,
        padding: 24,
        alignItems: "center",
        flex: 1,
        position: "absolute",
        zIndex: visible ? 99 : 0,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%",
      }}
    >
      <View style={{ marginBottom: 50 }}>
        <Shimmer width={100} height={HEIGHT * 2} />
      </View>
      {textRow}
      {textRow}
      {textRow}
      {textRow}
      {textRow}
      {textRow}
    </View>
  );
};

const BUTTON_MARGIN = 24;
const BUTTON_SIZE = 40;

const NextChapterButton = ({ onPress }: { onPress: () => void }) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: 8,
        padding: 12,
        maxHeight: BUTTON_SIZE,
        margin: BUTTON_MARGIN,
      }}
    >
      <Text style={{ fontSize: 16 }}>Next Chapter</Text>
    </Pressable>
  );
};

const PurchaseScreen = ({
  chapterData,
  navigateToPurchaseBean,
}: {
  chapterData: ChapterData,
  navigateToPurchaseBean: () => void;
}) => {
  const { activeReaderTheme } = useReaderPreferencesContext();
  const {
    data: fetchViewerData,
    loading: fetchViewerLoading,
  } = useQuery<FetchViewerData>(FETCH_VIEWER);

  const [purchaseChapter, { loading }] = useMutation<
    PurchaseChapterData,
    PurchaseChapterInput
  >(PURCHASE_CHAPTER, {
    update: (cache, { data: purchaseChapterData }) => {
      const viewerData = cache.readQuery<FetchViewerData>({
        query: FETCH_VIEWER,
      });
      if (viewerData !== null) {
        cache.writeQuery({
          query: FETCH_VIEWER,
          data: {
            viewer: {
              ...viewerData.viewer,
              beans: purchaseChapterData?.purchaseChapter.user.beans,
            },
          },
        });
        if (purchaseChapterData !== null) {
          logEvent("purchase_chapter", {
            chapter_id: chapterData.id,
            chapter_index: chapterData.chapterIndex,
            book_id: chapterData.book.id,
            book_name: chapterData.book.name,
            beanPrice: chapterData.beanPrice,
          });
        }
      }
    },
  });

  const notEnoughBeans =
    !!fetchViewerData &&
    !!fetchViewerData.viewer &&
    fetchViewerData.viewer.beans < chapterData.beanPrice;

  return (
    <View
      style={{
        backgroundColor: activeReaderTheme.backgroundColor,
        padding: 24,
        alignItems: "center",
        flex: 1,
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Text style={{ fontSize: 24, color: activeReaderTheme.textColor }}>
        Chapter is locked
      </Text>
      {fetchViewerLoading ? (
        <View style={{ marginVertical: 12 }}>
          <Shimmer width={Dimensions.get("window").width * 0.5} height={50} />
        </View>
      ) : (
        <>
          {fetchViewerData && <MagicBeanButton viewerData={fetchViewerData} navigateToPurchaseBean={navigateToPurchaseBean}/>}
          <Pressable
            style={{
              backgroundColor: loading || notEnoughBeans ? "#B8B8B8" : "#8E2BFE",
              marginTop: 36,
              padding: 12,
              borderRadius: 8,
            }}
            onPress={() => {
              purchaseChapter({ variables: { chapterId: chapterData.id } });
            }}
          >
            <Text style={{color: 'white', fontWeight: 'bold'}}>
              Unlock for {chapterData.beanPrice} Coffee Beans!
            </Text>
          </Pressable>
        </>
      )}
      { chapterData.book.unlockableByReading && <Text style={{ fontWeight: 'bold', 
                color: 'black', marginTop: 100 }}>
      This chapter is free to read!! Unlocks every hour.
      </Text> }
    </View>
  );
};

const ChapterScreen = ({
  chapterId,
  onExitPress,
  navigateToBook,
  navigateToChapter,
  navigateToPurchaseBean,
}: {
  chapterId: number;
  onExitPress?: () => void;
  navigateToBook: (bookId: number) => void;
  navigateToChapter: (chapterId: number) => void;
  navigateToPurchaseBean: () => void;
}) => {
  const {
    increaseFontSize,
    decreaseFontSize,
    headerFontSize,
    bodyFontSize,
    setFontFamilyIndex,
    fontFamily,
    activeFont,
    activeReaderTheme,
    setReaderThemeIndex,
  } = useReaderPreferencesContext();

  const [updateReadingProgress] = useMutation<
    UpdateReadingProgressData,
    UpdateReadingProgressInput
  >(UPDATE_READING_PROGRESS);
  const scrollViewRef = React.useRef<ScrollView>(null);

  const [overlayVisible, setOverlayVisible] = React.useState(false);

  const [topOffset, setTopOffset] = React.useState<number | null>(null);
  const [layoutMeasurement, setLayoutMeasurement] = React.useState<number>(0.0);
  const [totalScrollHeight, setTotalScrollHeight] = React.useState<
    number | null
  >(null);

  const [impressionLogged, setImpressionLogged] = React.useState(false);

  const [
    scrollToPositionCompleted,
    setScrollToPositionCompleted,
  ] = React.useState(false);
  const [fetchCompleted, setFetchCompleted] = React.useState(false);
  const { data: fetchChapterData } = useQuery<FetchChapterData>(FETCH_CHAPTER, {
    variables: { id: chapterId },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      setFetchCompleted(true);
    },
  });

  const toggleOverlays = () => {
    setOverlayVisible(!overlayVisible);
  };

  const syncReadingProgressState = async () => {
    if (totalScrollHeight === null || topOffset === null) {
      return null;
    }
    const newPercent = (topOffset / totalScrollHeight) * 100;
    const bottomOffsetPercent =
      ((layoutMeasurement + topOffset) / totalScrollHeight) * 100;

    return await updateReadingProgress({
      variables: {
        chapterId: chapterId,
        floatPercentComplete: Math.max(0, newPercent), // 1 percent offset
        isComplete: bottomOffsetPercent >= 95,
      },
    });
  };

  React.useEffect(() => {
    setImpressionLogged(false);
  }, [chapterId]);
  React.useEffect(() => {
    if (!!fetchChapterData && !impressionLogged) {
      setImpressionLogged(true);
      logEvent("read_chapter", {
        chapter_id: fetchChapterData.chapterById.id,
        chapter_index: fetchChapterData.chapterById.chapterIndex,
        book_id: fetchChapterData.chapterById.book.id,
        book_name: fetchChapterData.chapterById.book.name,
        beanPrice: fetchChapterData.chapterById.beanPrice,
        unlockedByCoupon: fetchChapterData.chapterById.unlockedByCoupon,
        unlockedByReading: fetchChapterData.chapterById.unlockedByReading,
      });
    }
  }, [fetchChapterData, impressionLogged]);

  React.useEffect(() => {
    if (!scrollToPositionCompleted && fetchCompleted) {
      const ref = scrollViewRef.current;
      if (
        ref !== null &&
        totalScrollHeight !== null &&
        fetchChapterData !== undefined
      ) {
        if (fetchChapterData.chapterById.isComplete) {
          ref.scrollToEnd({ animated: false });
        } else {
          const percentRead =
            (fetchChapterData?.chapterById.percentComplete ?? 0) / 100;

          ref.scrollTo({
            y: totalScrollHeight * Math.max(0, percentRead),
            animated: false,
          });
        }

        // TODO(ericning): We miss the scroll reset sometimes on first load, let's investigate why
        setScrollToPositionCompleted(true);
      }

      return;
    }

    if (scrollToPositionCompleted && fetchCompleted) {
      syncReadingProgressState();
    }
  }, [
    scrollToPositionCompleted,
    totalScrollHeight,
    topOffset,
    fetchCompleted,
    scrollViewRef.current,
  ]);

  const navigateToChapterWrapper = (chapterId: number) => {
    setScrollToPositionCompleted(false);
    syncReadingProgressState();
    resetState();
    navigateToChapter(chapterId);
  };

  const resetState = () => {
    setScrollToPositionCompleted(false);
    setFetchCompleted(false);
    setTotalScrollHeight(null);
    setTopOffset(null);
  };

  const nextChapter =
    fetchChapterData !== undefined
      ? fetchChapterData.chapterById.book.chapters.find(
          (chapter) =>
            chapter.chapterIndex ===
            fetchChapterData.chapterById.chapterIndex + 1
        )
      : undefined;

  const percent =
    topOffset === null || totalScrollHeight === null
      ? 0
      : Math.round((topOffset / totalScrollHeight) * 100);

  if (fetchChapterData?.chapterById.requiresPurchase) {
    return <PurchaseScreen chapterData={fetchChapterData.chapterById} navigateToPurchaseBean={navigateToPurchaseBean} />;
  }

  return (
    // This absolute might be a quirk of react navigation vs react router
    <View
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 999,
        // @ts-ignore: fix safari overscroll
        touchAction: "none",
      }}
    >
      <LoadingScreen visible={!scrollToPositionCompleted} />
      <ScrollView
        ref={scrollViewRef}
        stickyHeaderIndices={[0]}
        onContentSizeChange={(_, height) => {
          // Hack currently for when we load the scrollview
          if (height > 200) {
            setTotalScrollHeight(height);
          }
        }}
        onScroll={(event) => {
          setTopOffset(event.nativeEvent.contentOffset.y);
          setLayoutMeasurement(event.nativeEvent.layoutMeasurement.height);
        }}
        scrollEventThrottle={160}
        style={{
          backgroundColor: activeReaderTheme.backgroundColor,
        }}
      >
        <Fade visible={overlayVisible}>
          <View
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              width: "100%",
              justifyContent: "center",
              backgroundColor: activeReaderTheme.menuBackgroundColor,
              borderBottomWidth: 1,
              borderBottomColor: activeReaderTheme.borderColor,
            }}
            pointerEvents={overlayVisible ? "auto" : "none"}
          >
            <View style={{ padding: 12 }}>
              <Pressable
                onPress={() =>
                  fetchChapterData?.chapterById.book.id && !onExitPress
                    ? navigateToBook(fetchChapterData.chapterById.book.id)
                    : onExitPress && onExitPress()
                }
                style={{ alignItems: "center", flexDirection: "row" }}
              >
                <Ionicons
                  name="chevron-back"
                  color={activeReaderTheme.menuTextColor}
                  size={iconSize}
                />
                <Text
                  style={{
                    fontSize: 16,
                    marginLeft: 12,
                    color: activeReaderTheme.menuTextColor,
                  }}
                >
                  {fetchChapterData?.chapterById.book.name}
                </Text>
              </Pressable>
            </View>
          </View>
        </Fade>
        <Pressable
          style={{ marginTop: 36, padding: 18 }}
          onPress={() => toggleOverlays()}
        >
          <Text
            style={{
              textAlign: "center",
              fontFamily: fontFamily,
              fontSize: headerFontSize,
              color: activeReaderTheme.textColor,
            }}
          >
            {fetchChapterData?.chapterById.name}
          </Text>
          <Text
            style={{
              marginTop: 36,
              fontFamily: fontFamily,
              fontSize: bodyFontSize,
              color: activeReaderTheme.textColor, 
            }}
            selectable={false}
          >
            <ReactMarkdown>
              {fetchChapterData?.chapterById.text ?? ""}
            </ReactMarkdown>
          </Text>
        </Pressable>
        {nextChapter !== undefined && (
          <NextChapterButton
            onPress={() => navigateToChapterWrapper(nextChapter.id)}
          />
        )}
      </ScrollView>
      <Fade visible={overlayVisible}>
        <View
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            alignItems: "stretch",
            justifyContent: "center",
            backgroundColor: activeReaderTheme.menuBackgroundColor,
            borderTopWidth: 1,
            borderTopColor: activeReaderTheme.borderColor,
          }}
          pointerEvents={overlayVisible ? "auto" : "none"}
        >
          <ProgressBar percent={percent} />
          <View style={{ paddingVertical: 12, paddingHorizontal: 24 }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                borderBottomWidth: 1,
                borderBottomColor: activeReaderTheme.borderColor,
                paddingBottom: 12,
                marginBottom: 12,
                flex: 1,
              }}
            >
              {readerThemes.map((theme, index) => {
                const size = 32;
                return (
                  <Pressable
                    key={`theme-${index}`}
                    style={{
                      height: size,
                      width: size,
                      borderRadius: size,
                      backgroundColor: theme.backgroundColor,
                      borderColor:
                        activeReaderTheme.backgroundColor ===
                        theme.backgroundColor
                          ? "#4d9feb"
                          : "black",
                      borderWidth:
                        activeReaderTheme.backgroundColor ===
                        theme.backgroundColor
                          ? 2
                          : 1,
                      marginHorizontal: 8,
                    }}
                    onPress={() => setReaderThemeIndex(index)}
                  />
                );
              })}
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                borderBottomWidth: 1,
                borderBottomColor: activeReaderTheme.borderColor,
                paddingBottom: 12,
                // Hack to center elemnts for now
                paddingRight: 16,
                flex: 1,
              }}
            >
              {Object.values(Font).map((font, index) => (
                <FontSelector
                  key={font}
                  font={font as Font}
                  selected={font === activeFont}
                  onPress={() => setFontFamilyIndex(index)}
                />
              ))}
            </View>
            <View
              style={{
                flexDirection: "row",
                marginTop: 8,
                alignSelf: "center",
              }}
            >
              <Pressable onPress={decreaseFontSize} style={{ padding: 4 }}>
                <Ionicons
                  name="remove"
                  color={activeReaderTheme.menuTextColor}
                  size={iconSize}
                />
              </Pressable>
              <View style={{ width: 40, justifyContent: "center" }}>
                <Text
                  style={{
                    fontSize: 24,
                    textAlign: "center",
                    color: activeReaderTheme.menuTextColor,
                  }}
                >
                  A
                </Text>
              </View>
              <Pressable onPress={increaseFontSize} style={{ padding: 4 }}>
                <Ionicons
                  name="add"
                  color={activeReaderTheme.menuTextColor}
                  size={iconSize}
                />
              </Pressable>
            </View>
          </View>
        </View>
      </Fade>
    </View>
  );
};

export default ChapterScreen;
