import React from "react";
import { Animated, Text, View } from "react-native";

const height = 6;

const ProgressBar = ({ percent }: { percent: number }) => {
  const [widthAnimation] = React.useState(new Animated.Value(percent));

  React.useEffect(() => {
    Animated.timing(widthAnimation, {
      toValue: percent,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [percent]);

  const width = widthAnimation.interpolate({
    inputRange: [0, 100],
    outputRange: ["0%", "100%"],
    extrapolate: "clamp",
  });

  return (
    <View style={{ height: height, width: "100%" }}>
      <Animated.View style={{ backgroundColor: "#888888", width, height: height }} />
    </View>
  );
};

export default ProgressBar;
