const AppStyles = {
  pageBackgroundColor: '#1b1b20',
  text: {
    mainText: {
      color: 'white',
      font: 'Vollkorn'
    },
    secondaryText: {
      color: '#bababa',
      font: 'Futura'
    }
  },
  paragraphFontSize: 14,
  tabHeadingsSize: 16,
  carouselOrder: ["Free Reads", "Free to Read", "The Alpha's Mate", "Marked by the Mafia", "Erotic Romance", "Paranormal Romance", "The Billionaire Obsessed With Me", "Exclusive to Stori"],
};

export default AppStyles;