import amplitude from "amplitude-js";
amplitude
  .getInstance()
  .init(
    process.env.NODE_ENV === "production"
      ? "1375b53449d43aaa7358ae6ad6239cb1"
      : "afe19b42014b667cb9d44386e1cdf21c"
  );

export const initUser = ({
  userId,
  username,
  email,
}: {
  userId: string;
  username: string;
  email: string;
}) => {
  amplitude.getInstance().setUserId(userId);
  amplitude.getInstance().setUserProperties({
    username: username,
    email: email,
  });
};

export const logEvent = (event: string, data?: any) => {
  console.log("Log Event: ", event, data);
  amplitude.getInstance().logEvent(event, data);
};
