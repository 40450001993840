import React from "react";
import {
  Linking,
  Image,
  Text,
  Platform,
  Pressable,
  ScrollView,
  View,
  TouchableOpacity,
} from "react-native";
import { Ionicons, Entypo } from "@expo/vector-icons";
import { gql, useQuery, useMutation } from "@apollo/client";
import BookThumbnail from "../components/BookThumbnail";
import Shimmer from "../components/Shimmer";
import HomeScreenTitle from "../components/HomeScreenTitle";

import AppStyles from "../AppStyles";
import Markdown from "react-native-markdown-display";

const BOOK_QUERY = `
id
name
authors {
  name
}
shortDescription
coverImageUrl
`;

export const FETCH_AUTHOR = gql`
  query FetchAuthorById($id: String!) {
    authorById(id: $id) {
      id
      name
      description
      twitterHandle
      isFollowing
      books {
        ${BOOK_QUERY}
      }
      profilePicUrl
    }
  }
`;

const FETCH_BONUS_CONTENT = gql`
  query FetchBonusContent($authorId: Int!) {
    bonusContent(authorId: $authorId) {
      id
      link
      additionalInfo
      type {
        id
        name
      }
    }
  }
`;

interface BookAuthor {
  id: number;
  name: string;
}

interface BookData {
  id: number;
  coverImageUrl: string;
  name: string;
  shortDescription: string;
  authors: Array<BookAuthor>;
}

interface AuthorData {
  id: string;
  name: string;
  description: string;
  twitterHandle?: string;
  isFollowing: boolean;
  books: Array<BookData>;
  profilePicUrl: string;
}

interface FetchAuthorData {
  authorById: AuthorData;
}

const FOLLOW_AUTHOR = gql`
  mutation FollowAuthor($id: Int!, $follow: Boolean!) {
    followAuthor(id: $id, follow: $follow) {
      id
      isFollowing
    }
  }
`;

interface FavoriteData {
  __typename: String;
  id: number;
  isFollowing: boolean;
}

interface FollowAuthorData {
  __typename: String;
  followAuthor: FavoriteData;
}

interface FollowAuthorInput {
  id: string;
  follow: boolean;
}

interface BonusContentType {
  id: number;
  name: string;
}

interface BonusContent {
  id: number;
  link: string;
  additionalInfo: string;
  type: BonusContentType;
}

interface BonusContentData {
  bonusContent: Array<BonusContent>;
}

const PROFILE_PIC_SIZE = 120;

const AuthorScreen = ({
  authorId,
  onBackPress,
  navigateToBook,
}: {
  authorId: string;
  onBackPress: () => void;
  navigateToBook: (bookId: number) => void;
}) => {
  const { data } = useQuery<FetchAuthorData>(FETCH_AUTHOR, {
    variables: { id: authorId },
  });

  const [followAuthor] = useMutation<FollowAuthorData, FollowAuthorInput>(
    FOLLOW_AUTHOR
  );

  const {data: bonusContentData, error} = useQuery<BonusContentData>(FETCH_BONUS_CONTENT, {
    variables: {authorId: Number(data?.authorById.id)},
    fetchPolicy: 'cache-and-network'
  });

  return (
    <ScrollView
      style={{ backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <TouchableOpacity
        style={{ position: "absolute", left: 18, top: 18, zIndex: 1 }}
        onPress={onBackPress}
      >
        <Ionicons name="chevron-back" color="white" size={36} />
      </TouchableOpacity>
      <View style={{ marginTop: 24, alignItems: "center", paddingTop: 24 }}>
        {data?.authorById !== undefined ? (
          <Image
            source={{
              uri: data?.authorById.profilePicUrl,
            }}
            style={{
              height: PROFILE_PIC_SIZE,
              width: PROFILE_PIC_SIZE,
              borderRadius: PROFILE_PIC_SIZE,
            }}
          />
        ) : (
          <Shimmer
            width={PROFILE_PIC_SIZE}
            height={PROFILE_PIC_SIZE}
            borderRadius={PROFILE_PIC_SIZE}
          />
        )}
        {data?.authorById === undefined && (
          <View style={{ marginTop: 24 }}>
            <Shimmer height={120} width={200} borderRadius={12} />
          </View>
        )}
        {data?.authorById && (
          <>
            <Text
              style={{
                color: AppStyles.text.mainText.color,
                textAlign: "center",
                marginTop: 24,
                fontSize: 24,
                fontFamily: AppStyles.text.secondaryText.font,
              }}
            >
              {data?.authorById.name}
            </Text>
            {data.authorById.twitterHandle && (
              <Pressable
                style={{
                  padding: 12,
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onPress={() => {
                  const link = `https://www.twitter.com/${data?.authorById?.twitterHandle}`;
                  if (Platform.OS === "web") {
                    window.open(link);
                  } else {
                    Linking.openURL(link);
                  }
                }}
              >
                <Ionicons
                  name="logo-twitter"
                  color="white"
                  size={16}
                  style={{ marginTop: 4 }}
                />
                <Text
                  style={{
                    color: AppStyles.text.mainText.color,
                    marginLeft: 4,
                    textDecorationLine: "underline",
                    fontFamily: AppStyles.text.secondaryText.font,
                  }}
                >
                  {data?.authorById?.twitterHandle}
                </Text>
              </Pressable>
            )}
            <TouchableOpacity
              style={{
                backgroundColor: "white",
                padding: 12,
                borderRadius: 12,
              }}
              onPress={() =>
                followAuthor({
                  variables: {
                    id: data.authorById.id,
                    follow: !data.authorById.isFollowing,
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    followAuthor: {
                      __typename: "Author",
                      id: parseInt(data.authorById.id),
                      isFollowing: !data.authorById.isFollowing,
                    },
                  },
                })
              }
            >
              <Text style={{ fontSize: 14 }}>
                {data.authorById.isFollowing ? "Followed" : "Follow"}
              </Text>
            </TouchableOpacity>
            <Markdown
              style={{
                body:{
                  color: AppStyles.text.mainText.color,
                  paddingHorizontal: 24,
                  marginTop: 24,
                  fontFamily: AppStyles.text.secondaryText.font,
                }
              }}
            >
              {data?.authorById?.description}
            </Markdown>
          </>
        )}
      </View>
      {data?.authorById && (
        <View style={{ marginBottom: 24, padding: 24 }}>
          <View style={{ marginVertical: 8 }}>
            <HomeScreenTitle text="Books" />
          </View>
          {data?.authorById.books.map((book) => {
            return (
              <View style={{ marginTop: 12 }} key={book.id}>
                <BookThumbnail
                  key={`author-book-${book.id}`}
                  book={{
                    name: book.name,
                    description: book.shortDescription,
                    author: book.authors[0].name,
                    coverImageUrl: book.coverImageUrl,
                  }}
                  onPress={() => navigateToBook(book.id)}
                />
              </View>
            );
          })}
        </View>
      )}
      {bonusContentData?.bonusContent && bonusContentData?.bonusContent.length > 0 &&
      (
        <View style={{marginBottom: 24}}>
          <View style={{marginTop: 16}}>
            <HomeScreenTitle text="Bonus Content" />
          </View>
          {bonusContentData?.bonusContent.map(content => {
            let icon = () => { return (<Entypo
                name='text'
                size={26}
                color='white'
                style={{
                  paddingVertical: 18,
                }}
              />);
              }
            if (content.type.name === 'Spotify') {
              icon = () => { return (<Entypo
                  name='spotify'
                  size={26}
                  color='white'
                  style={{
                    paddingVertical: 18,
                  }}
                />);
                }
            }
            else if (content.type.name === 'Youtube') {
              icon = () => { return (<Entypo
                  name='youtube'
                  size={26}
                  color='white'
                  style={{
                    paddingVertical: 18,
                  }}
                />);
                }
            }
            else if (content.type.name === 'Pinterest') {
              icon = () => { return (<Entypo
                  name='pinterest'
                  size={26}
                  color='white'
                  style={{
                    paddingVertical: 18,
                  }}
                />);
              }
            }
            else if (content.type.name == 'Recipe') {
              icon = () => { return (<Entypo
                  name='bowl'
                  size={26}
                  color='white'
                  style={{
                    paddingVertical: 18,
                  }}
                />);
                }
            }
            return (
              <View
                style={{paddingVertical: 8, paddingHorizontal: 12}}
                key={content.id}>
                <Pressable
                  onPress={() => {
                    const link = content.link;
                    Linking.openURL(link);
                  }}>
                  <Text style={{color:'white'}}>
                    {icon()}   {content.additionalInfo} 
                  </Text>
                </Pressable>
              </View>
            );
          })}
        </View>
      )
      }
    </ScrollView>
  );
};

export default AuthorScreen;
