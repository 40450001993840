import React, { useRef, useState } from "react";
import { Text, ScrollView, View, Image, TouchableOpacity, Dimensions, Animated, FlatList } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { gql, useQuery } from "@apollo/client";

import AppStyles from "../AppStyles";
import { FETCH_VIEWER, FetchViewerData, FETCH_CAROUSELS, CarouselListData } from '../Queries';
import Banner from '../components/Banner';
import DisplayCarousel from "../components/DisplayCarousel";
import { withRouter } from "react-router";

const WIDTH = 110;
const { width } = Dimensions.get("window");
const offsetSignIn = width - 2.5 * WIDTH;


const AnonUserScreen = ({ toggle }: { toggle: (id : number) => void }) => {
  const {
    data: carouselBooksData,
    loading: carouselDataLoading,
    refetch: refetchCarousels,
  } = useQuery<CarouselListData>(FETCH_CAROUSELS, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    pollInterval:300000,
  });

  const checkCarouselMatch = ( {
    desiredMatch,
    carouselName,
  }: {
    desiredMatch: string;
    carouselName: string;
  }) => {
    for (let idx = 0; idx < Math.min(desiredMatch.length, carouselName.length); idx++) {
      if (desiredMatch[idx] !== carouselName[idx]) {
        return false;
      }
    }
    return carouselName.length >= desiredMatch.length;
  }

  // Data
  let DATA = [];
  let carouselToIndex = new Map();
  let carouselOrder = AppStyles.carouselOrder;
  let carouselIndexSet = new Set();
  if (carouselBooksData) {
    let banner_pos = null;
    for (let i = 0; i < carouselBooksData?.carousels.length; ++i) {
      if (carouselBooksData?.carousels[i].name.substring(0, 6) === "Banner") {
        banner_pos = i;
        continue;
      }
      for (const str of carouselOrder) {
        if (checkCarouselMatch({ desiredMatch: str, carouselName: carouselBooksData?.carousels[i].name })) {
          carouselToIndex.set(str, i);
          carouselIndexSet.add(i);
          break;
        }
      }
    }
    if (banner_pos !== null) {
      DATA.push({ type: "banner", id: banner_pos})
    }
    DATA.push({ type: "reading", id: 0 });
    for (const str of carouselOrder) {
      if (carouselToIndex.has(str)) {
        DATA.push({type: "carousel", id: carouselToIndex.get(str)});
      }
    }
    for (let i = 0; i < carouselBooksData?.carousels.length; ++i) {
      if (!carouselIndexSet.has(i) && i !== banner_pos) {
        DATA.push({type: "carousel", id: i });
      }
    }
  }
  const renderItem = ( {
    item
  }: {
    item: any;
  }) => {
    if (item.type === "banner") {
      return (<Banner bannerItems={carouselBooksData?.carousels[item.id].carouselItems} navigateToAuthor={toggle} navigateToBook={toggle} />);
    } else if (item.type === "carousel") {
      if (carouselBooksData && carouselBooksData?.carousels[item.id].carouselItems.length > 0
            && carouselBooksData?.carousels[item.id].hidden === false) {
        return (<DisplayCarousel carousel={carouselBooksData?.carousels[item.id]} navigateToBook={toggle} />);
      }
    }
    return (<View/>);
  };

  return (
    <ScrollView
      style={{ backgroundColor: AppStyles.pageBackgroundColor, padding:20 }}
    >
      <View>
        <HeaderView toggle={toggle}></HeaderView>
      </View>
      <FlatList
        data={DATA}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
        style={{
          flex: 1,
          backgroundColor: AppStyles.pageBackgroundColor,
        }}
        contentContainerStyle={{
          backgroundColor: AppStyles.pageBackgroundColor,
        }}
        initialNumToRender={4}
        maxToRenderPerBatch={4}
        windowSize={5}
        removeClippedSubviews
        alwaysBounceVertical={false}
        />
    </ScrollView>
  );
};

const HeaderView = ({ toggle }: { toggle: (id : number) => void }) => {
  return (
    <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        <Image
          source={require('../assets/stori-script.png')}
          style={{
            width: WIDTH,
            height: 70,
            // backgroundColor: imageLoading ? '#333333' : undefined,
          }}
        />
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => toggle(0)}>
          <Text
            style={{
              textAlign: 'center',
              marginTop: 20,
              marginLeft: offsetSignIn,
              fontSize: 20,
              fontFamily: AppStyles.text.secondaryText.font,
              textDecorationLine: "underline",
              color: 'white',
            }}>
            Sign In
          </Text>
        </TouchableOpacity>
      </ScrollView>
  );
}

export default AnonUserScreen;
