import { gql } from "@apollo/client";

export const BOOK_QUERY = `
id
name
authors {
  name
}
shortDescription
coverImageUrl
`;

export interface BookAuthor {
  id: number;
  name: string;
}

export interface BookData {
  id: number;
  coverImageUrl: string;
  name: string;
  shortDescription: string;
  author: BookAuthor;
}

const CHAPTER_QUERY = `
id
name
chapterIndex
isComplete
percentComplete
purchased
requiresPurchase
seasonIndex
unlockedByCoupon
unlockedByReading
`;

export const BOOK_DATA_QUERY = `
id
coverImageUrl
name
shortDescription
description
author {
  id
  name
  alias
}
chapters {
${CHAPTER_QUERY}
}
genres {
  id
  label
  displayName
}
latestChapterRead {
  ${CHAPTER_QUERY}
}
seasons {
  id
  seasonIndex
  description
  name
  requiresPurchase
  seasonPassBeanPrice
  disableSeasonPass
}
`

export const EXTENDED_BOOK_QUERY = `
id
coverImageUrl
name
shortDescription
description
author {
  id
  name
  alias
}
favorited
followerCount
goodreadsUrl
matureThemes
viewCount
readerCount`;

export const USER_QUERY = `
favoritedBooks {
  ${BOOK_QUERY}
}
currentlyReadingBooks {
  ${EXTENDED_BOOK_QUERY}
}
redeemedBookCoupons {
  id
  expiresAt
  createdAt
  chapterUnlockCountRemaining
  book {
    id
    name
    coverImageUrl
  }
}
id
beans
profilePictureUrl`;

export const STRIPE_SESSION_QUERY = `
url
`;

export const FETCH_VIEWER = gql`
  query FetchViewer {
    viewer {
      ${USER_QUERY}
    }
  }
`;

export const FETCH_CAROUSELS = gql`
  query FetchCarousels {
    carousels {
      id
      name
      hidden
      carouselItems {
        type
        imageUrl
        genre {
          id
          label
          displayName
        }
        book {
          id
          name
          author {
            name
          }
          shortDescription
          coverImageUrl
        }
      }
    }
  }
`;

export interface CarouselListData {
  carousels: Array<CarouselData>;
}

export interface ViewerData {
  id: number,
  favoritedBooks: Array<BookData>;
  currentlyReadingBooks: Array<ExtendedBookData>;
  redeemedBookCoupons: Array<RedeemedCouponData>;
  profilePictureUrl?: string;
  beans: number;
}

export interface FetchViewerData {
  viewer: ViewerData | null;
}

export interface RedeemedCouponData {
  id: number,
  expiresAt: string;
  createdAt: string;
  chapterUnlockCountRemaining: number;
  book: BookData;
}

interface Author {
  id: number;
  name: string;
  alias?: string;
}

interface Genre {
  id: string;
  label: string;
  displayName: string;
}

interface Seasons {
  id: number;
  requiresPurchase: boolean;
  name: string;
  description: string;
  seasonIndex: number,
  seasonPassBeanPrice: number,
  disableSeasonPass: boolean;
}

export interface CarouselData {
  id: number;
  name: string;
  hidden: boolean;
  carouselItems: Array<CarouselItemsData>;
}

export interface CarouselItemsData {
  type: String;
  imageUrl?: String;
  genre?: Genre;
  book?: BookData;
}

export interface Chapter {
  id: number;
  name: string;
  chapterIndex: number;
  isComplete: boolean;
  percentComplete: number;
  purchased: boolean;
  requiresPurchase: boolean;
  seasonIndex: number;
  unlockedByCoupon: boolean;
  unlockedByReading: boolean;
}

export interface ExtendedBookData {
  id: number;
  coverImageUrl: string;
  name: string;
  description: string;
  shortDescription: string;
  chapters: Array<Chapter>;
  author: Author;
  genres: Array<Genre>;
  seasons: Array<Seasons>;
  favorited: boolean;
  followerCount: number;
  latestChapterRead?: Chapter;
  goodreadsUrl?: string;
  userFinished: boolean;
  matureThemes: boolean;
  viewCount: number | null;
}

export interface StripeBeanPurchaseSession {
  url: string,
}
