import React, { useCallback, useEffect, useState } from "react";
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject
} from "@apollo/client";
import {LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { extractFiles } from 'extract-files';
import {createUploadLink} from 'apollo-upload-client';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {ActionSheetProvider} from '@expo/react-native-action-sheet';

import {
  AuthContextProvider,
  ACCESS_TOKEN_KEY,
} from "./src/context/AuthContext";
import { FontContextProvider } from "./src/context/FontContext";
import { ReaderPreferencesProvider } from "./src/context/ReaderPreferencesContext";
import CoreApp from './src/CoreApp';

const authLink = setContext(async (_, { headers }) => {
  const token = await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const apolloURI = process.env.NODE_ENV === 'development'
  ? "http://localhost:3000/"
  : "https://stori-backend.herokuapp.com"

const uploadAndBatchHTTPLink = () => ApolloLink.split(
  operation => extractFiles(operation).files.size > 0,
  createUploadLink({
    uri: apolloURI,
    headers: {
      "keep-alive": "true"
    }
  }),
  new BatchHttpLink({
    uri: apolloURI,
  })
);

export const apolloLink = authLink.concat(uploadAndBatchHTTPLink());

// const client = new ApolloClient({
//   link: apolloLink,
//   cache: new InMemoryCache(),
// });

export default function App() {
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-device-width: 600px)",
  // });
  // if (isDesktopOrLaptop) {
  //   return (
  //     <View>
  //       <Text>This site doesn't work on desktop yet...</Text>
  //     </View>
  //   );
  // }

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [persistor, setPersistor] = useState<
    CachePersistor<NormalizedCacheObject>
  >();

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write',
      });
      await newPersistor.restore();
      setPersistor(newPersistor);
      setClient(
        new ApolloClient({
          link: apolloLink,
          cache: cache,
        }),
      );
    }

    init().catch(console.error);
  }, []);

  if (!client) {
    return <h2>Setting up Stori...</h2>;
  }

  return (
    <ActionSheetProvider>
      <ApolloProvider client={client}>
        <FontContextProvider>
          <ReaderPreferencesProvider>
            <AuthContextProvider>
              <CoreApp />
            </AuthContextProvider>
          </ReaderPreferencesProvider>
        </FontContextProvider>
      </ApolloProvider>
    </ActionSheetProvider>
  );
}
