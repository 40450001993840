import React, { useState } from "react";
import {
  Image,
  Pressable,
  Text,
  TextInput,
  ScrollView,
  View,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { useActionSheet } from '@expo/react-native-action-sheet';
import { gql, useMutation, useQuery } from "@apollo/client";

import AppStyles from "../AppStyles";
import { useAuthContext } from "../context/AuthContext";
import BookThumbnail from "../components/BookThumbnail";
import Shimmer from "../components/Shimmer";
import AppleLoginButton from "../components/AppleLoginButton";
import { USER_QUERY, ViewerData, RedeemedCouponData } from "../Queries";
import MagicBeanButton from "../components/MagicBeanButton";
import { ReactNativeFile } from "extract-files";
import * as ImagePicker from "expo-image-picker";
import AnonUserScreen from "./AnonUserScreen";

const BOOK_QUERY = `
id
name
authors {
  name
}
shortDescription
coverImageUrl
`;

export const FETCH_VIEWER = gql`
  query FetchViewer {
    viewer {
      ${USER_QUERY}
    }
  }
`;

const UPLOAD_PROFILE_PIC = gql`
  mutation uploadProfilePicture($file: Upload!) {
    uploadProfilePicture(file: $file)
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

interface ResetPasswordInput {
  email: string;
}

enum LoggedOutState {
  LOGIN = 'login',
  CREATE_ACCOUNT = 'create_account',
  RESET_PASSWORD = 'reset_password',
  ANON_SCREEN = 'anon_screen',
}

const profilePictureUploadOptions = {
  width: 300,
  height: 300,
  cropping: true,
  cropperCircleOverlay: true,
};

interface UploadProfilePictureInput {
  file: Blob;
}

interface Author {
  id: number;
  name: string;
}

interface BookData {
  id: number;
  coverImageUrl: string;
  name: string;
  shortDescription: string;
  authors: Array<Author>;
}


export interface FetchViewerData {
  viewer: ViewerData | null;
}

const IMAGE_SIZE = 100;

const HomeScreenTitle = ({ text }: { text: String }) => {
  return (
    <Text
      style={{
        paddingHorizontal: 24,
        paddingVertical: 24,
        fontSize: 18,
        color: AppStyles.text.mainText.color,
        fontFamily: AppStyles.text.secondaryText.font,
      }}
    >
      {text}
    </Text>
  );
};

const LoginInput = ({
  placeholder,
  label,
  password,
  onChangeText,
}: {
  placeholder?: string;
  label: string;
  password?: boolean;
  onChangeText: (text: string) => void;
}) => {
  return (
    <View style={{ marginBottom: 24 }}>
      <Text style={{ color: AppStyles.text.mainText.color, paddingBottom: 12 }}>
        {label}
      </Text>
      <View
        style={{
          backgroundColor: "#3c3c3c",
          flex: 1,
          paddingLeft: 4,
          borderRadius: 8,
          maxWidth: 500,
        }}
      >
        <TextInput
          onChangeText={onChangeText}
          secureTextEntry={password}
          placeholder={placeholder}
          style={{
            // @ts-ignore: web
            outline: "none",
            padding: 16,
            flex: 1,
            color: "white",
            fontSize: 20,
            fontFamily: AppStyles.text.secondaryText.font,
            width: 500,
          }}
        />
      </View>
    </View>
  );
};

const LoginButton = ({ onPress }: { onPress: () => void }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: 8,
        padding: 12,
        maxWidth: 500,
      }}
    >
      <Text style={{ fontSize: 20, fontFamily: AppStyles.text.secondaryText.font, }}>Login</Text>
    </TouchableOpacity>
  );
};

const SubmitButton = ({ onPress }: { onPress: () => void }) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: 8,
        padding: 12,
        maxWidth: 500,
      }}
    >
    <Text style={{ fontSize: 20, fontFamily: AppStyles.text.secondaryText.font, }}>Submit</Text>
    </Pressable>
  );
};

const NullAccountScreen = () => {
  const [isLogin, setIsLogin] = React.useState<LoggedOutState>(LoggedOutState.ANON_SCREEN);

  if (isLogin == LoggedOutState.LOGIN) {  
    return (
      <LoginScreen 
        goToCreateAccount={() => setIsLogin(LoggedOutState.CREATE_ACCOUNT)}
        goToResetPassword={() => setIsLogin(LoggedOutState.RESET_PASSWORD)}
        goToAnonScreen={() => setIsLogin(LoggedOutState.ANON_SCREEN)}
      />
      );
  } else if (isLogin == LoggedOutState.CREATE_ACCOUNT) {
    return (
        <CreateAccountScreen toggle={() => setIsLogin(LoggedOutState.LOGIN)} />
      );
  } else if (isLogin == LoggedOutState.RESET_PASSWORD) {
    return (        
        <ResetPasswordScreen toggle={() => setIsLogin(LoggedOutState.LOGIN)}/>
      );
  } else if (isLogin == LoggedOutState.ANON_SCREEN) {
    return (        
        <AnonUserScreen toggle={(id: Number ) => setIsLogin(LoggedOutState.LOGIN)}/>
      );
  } else {
    return (<View></View>);
  }
};

const LoginScreen = ({ goToCreateAccount, goToResetPassword, goToAnonScreen }: 
  { 
    goToCreateAccount: () => void,
    goToResetPassword: () => void,
    goToAnonScreen: () => void,
  }) => {
  const { login, loginApple } = useAuthContext();

  const [username, setUsername] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [credentialError, setCredentialError] = React.useState(false);

  // Listen for apple authorization success
  document.addEventListener('AppleIDSignInOnSuccess', (data: any) => {
    console.log('success');
    console.log(data);
    loginApple(data.detail.authorization.id_token);
  });

  // Listen for apple authorization failures
  document.addEventListener('AppleIDSignInOnFailure', (error) => {
    console.log('fail');
    console.log(error);
  });

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{
        padding: 48,
      }}
    >
      <Image
        source={require('../assets/stori-script.png')}
        style={{
          width: 200,
          height: 130,
          // backgroundColor: imageLoading ? '#333333' : undefined,
        }}
        resizeMode="contain"
      />
      <Text
        style={{
          fontSize: 36,
          color: AppStyles.text.mainText.color,
          marginBottom: 36,
        }}
      >
      </Text>
      <AppleLoginButton />
      <LoginInput label="Email" onChangeText={(text) => setUsername(text)} />
      <LoginInput
        label="Password"
        onChangeText={(text) => setPassword(text)}
        password
      />
      {credentialError && (
        <Text
          style={{
            color: '#f03d25',
            marginBottom: 10,
            fontFamily: AppStyles.text.secondaryText.font,
          }}
        >
          Incorrect Email or Password
        </Text>
      )}
      <LoginButton
        onPress={async () => {
          if (username === undefined || password === undefined) {
            return;
          }
          try {
            await login(username, password);
          } catch (err) {
            setCredentialError(true);
          }
        }}
      />
      <TouchableOpacity onPress={goToCreateAccount}>
        <Text style={{
          color: AppStyles.text.mainText.color, marginTop: 32,
          fontFamily: AppStyles.text.secondaryText.font,
          textDecorationLine: "underline",
        }}>
          Create Account
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={goToResetPassword}>
        <Text style={{
          color: AppStyles.text.mainText.color, marginTop: 32,
          fontFamily: AppStyles.text.secondaryText.font,
          textDecorationLine: "underline",
        }}>
          Forgot Password?
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={goToAnonScreen}>
        <Text style={{
          color: AppStyles.text.mainText.color, marginTop: 32,
          fontFamily: AppStyles.text.secondaryText.font,
          textDecorationLine: "underline",
        }}>
          Skip Login For now
        </Text>
      </TouchableOpacity>
    </ScrollView>
  );
};

const ResetPasswordScreen = ({toggle}: {toggle: () => void}) => {
  const [resetPassword, {error: mutationError}] = useMutation<
    boolean,
    ResetPasswordInput
  >(RESET_PASSWORD);

  const [email, setEmail] = React.useState<string>();
  const [resetPasswordInProgress, setResetPasswordInProgress] = React.useState(
    true,
  );

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{
        padding: 48,
      }}
    >
      <Text
        style={{
          fontSize: 36,
          color: AppStyles.text.mainText.color,
          marginBottom: 36,
        }}
      >
        Reset Password
      </Text>
      <LoginInput label="Email" onChangeText={text => setEmail(text)} />
      <SubmitButton
        onPress={() => {
          if (email === undefined) {
            return;
          }
          resetPassword({
            variables: {
              email: email,
            },
          }).then(() => {
            // Toast.showWithGravity(
            //   `Password reset information sent to ${email}`,
            //   Toast.LONG,
            //   Toast.TOP,
            // );
            setResetPasswordInProgress(false);
          });
        }}
      />
      {resetPasswordInProgress===false && (
        <Text style={{color: AppStyles.text.mainText.color, fontSize: 18, marginTop: 32}}>
          Password reset information sent to {email}!
        </Text>
      )}
      <Pressable onPress={toggle}>
        <Text style={{color: AppStyles.text.mainText.color, marginTop: 32, textDecorationLine: 'underline'}}>
          Log in
        </Text>
      </Pressable>
    </ScrollView>
  );
};

const CreateAccountScreen = ({ toggle }: { toggle: () => void }) => {
  const { createUser } = useAuthContext();

  const [username, setUsername] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();

  const [createAccountError, setCreateAccountError] = React.useState(false);

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{
        padding: 48,
      }}
    >
      <Text
        style={{
          fontSize: 36,
          color: AppStyles.text.mainText.color,
          marginBottom: 36,
        }}
      >
        Create Account
      </Text>
      <LoginInput label="Email" onChangeText={(text) => setEmail(text.trim())} />
      <LoginInput label="Username" onChangeText={(text) => setUsername(text)} />
      <LoginInput
        label="Password"
        onChangeText={(text) => setPassword(text)}
        password
      />
      {createAccountError && (
        <Text
          style={{
            color: '#f03d25',
            marginBottom: 10,
          }}
        >
          Couldn't create that account. Invalid email or email has been used before.
        </Text>
      )}
      <LoginButton
        onPress={async () => {
          if (
            username === undefined ||
            password === undefined ||
            email === undefined
          ) {
            return;
          }
          try {
            await createUser(email, username, password);
          } catch (err) {
            setCreateAccountError(true);
          }
        }}
      />
      <Pressable onPress={toggle}>
        <Text style={{ color: AppStyles.text.mainText.color, marginTop: 32, textDecorationLine: 'underline' }}>
          Already have an account? Log in
        </Text>
      </Pressable>
    </ScrollView>
  );
};

const ProfileScreen = ({
  navigateToBook,
  navigateToPurchaseBean,
  onGearPress,
}: {
  navigateToBook: (bookId: number) => void;
  navigateToPurchaseBean: () => void;
  onGearPress: () => void;
}) => {
  const { user } = useAuthContext();
  const { showActionSheetWithOptions } = useActionSheet();

  if (user === null) {
    return <NullAccountScreen />;
  }

  const { data: fetchViewerData, refetch } = useQuery<FetchViewerData>(FETCH_VIEWER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const hasUserDataLoaded = !!fetchViewerData && !!fetchViewerData.viewer;

  const [
    isUpdatingProfilePicture,
    setIsUpdatingProfilePicture,
  ] = React.useState(false);
  const [uploadProfilePicture, { error }] = useMutation<
    string,
    UploadProfilePictureInput
  >(UPLOAD_PROFILE_PIC);

  const convertBase64ToBlob = async (base64: any) => {
    const response = await fetch(base64);
    const blob = await response.blob();
    return blob;
  };

  const handleProfilePicture = async (
    path: string,
  ) => {
    setIsUpdatingProfilePicture(true);
    uploadProfilePicture({
      variables: {
        file: await convertBase64ToBlob(path)
      },
    })
      .then(response => {
        console.log('Upload response', response);
        setProfilePicLoading(true);
        refetch();
      })
      .catch(error => {
        console.log('upload error', error);
        setProfilePicLoading(false);
      });
  };

  const onProfilePicPress = async () => {
    setDisplayActionSheet(false);
    showActionSheetWithOptions(
      {
        options: ['Upload Photo', 'Cancel'],
        cancelButtonIndex: 2,
        userInterfaceStyle: 'dark',
      },
      buttonIndex => {
        setDisplayActionSheet(true);
        if (buttonIndex === 0) {
          console.log('Launch image picker');
          ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            // aspect: [4, 3],
            quality: 0.3,
          }).then((response) => {
            if (!response.cancelled) {
              handleProfilePicture(
                response.uri,
              );
            }
          });
        } 
      },
    );
  }

  const ProfilePictureEditButton = (
    <TouchableOpacity
      style={{
        borderColor: 'grey',
        borderRadius: 30,
        position: 'absolute',
        right: 0,
        bottom: 0,
        backgroundColor: 'grey',
        alignItems: 'center',
      }}
      onPress={onProfilePicPress}>
      {isUpdatingProfilePicture ? (
        <View style={{ padding: 4 }}>
          <ActivityIndicator style={{ borderColor: 'white', alignSelf: 'center' }} />
        </View>
      ) : (
        <Ionicons
          name="add"
          color="white"
          size={30}
          style={{
            marginLeft: 2,
          }}
        />
      )}
    </TouchableOpacity>
  );

  const [profilePicLoading, setProfilePicLoading] = React.useState(true);
  const [displayActionSheet, setDisplayActionSheet] = React.useState(true);
  React.useEffect(() => {
    if (!!fetchViewerData?.viewer) {
      if (!fetchViewerData.viewer.profilePictureUrl) {
        setProfilePicLoading(false);
      }
    }
  }, [fetchViewerData]);

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: AppStyles.pageBackgroundColor }}
      contentContainerStyle={{
        paddingVertical: 48,
      }}
    >
      <Pressable
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: 24,
          zIndex: 1,
        }}
        onPress={onGearPress}
      >
        <Ionicons name="cog-outline" color="white" size={36} />
      </Pressable>
      <View style={{ marginBottom: 24, alignItems: "center" }}>
        {fetchViewerData?.viewer?.profilePictureUrl ? (
          <View>
            <Image
              source={{
                uri: fetchViewerData?.viewer?.profilePictureUrl,
              }}
              style={{
                height: IMAGE_SIZE,
                width: IMAGE_SIZE,
                borderRadius: IMAGE_SIZE,
                backgroundColor: 'transparent',
              }}
              onLoadEnd={() => {
                setIsUpdatingProfilePicture(false);
                setProfilePicLoading(false);
              }}
            />
            {ProfilePictureEditButton}
          </View>
        ) : (
          <View
            style={{
              height: IMAGE_SIZE,
              width: IMAGE_SIZE,
              borderRadius: IMAGE_SIZE,
              backgroundColor: 'white',
            }}>
            {ProfilePictureEditButton}
          </View>
        )}
        <Text
          style={{
            color: AppStyles.text.mainText.color,
            textAlign: "center",
            marginTop: 24,
            fontSize: 24,
            fontFamily: AppStyles.text.secondaryText.font,
          }}
        >
          {user?.username}
        </Text>
        {hasUserDataLoaded && fetchViewerData !== undefined && (
          <MagicBeanButton viewerData={fetchViewerData} navigateToPurchaseBean={navigateToPurchaseBean} />
        )}
      </View>
      <View style={{ marginBottom: 24 }}>
        <HomeScreenTitle text="Special Offers" />
        { displayActionSheet && fetchViewerData?.viewer?.redeemedBookCoupons
          ?.filter(coupon => coupon.chapterUnlockCountRemaining > 0)
          .map((coupon, index) => (
            <View style={{ marginVertical: 12, marginLeft: 24 }} key={`coupon-${index}`}>
              <BookThumbnail
                key={`continue-${index}`}
                book={{
                  name: coupon.book.name,
                  description: 'Only valid during our launch period',
                  author:
                    String(coupon.chapterUnlockCountRemaining) +
                    ' chapters free',
                  coverImageUrl: coupon.book.coverImageUrl,
                }}
                onPress={() => navigateToBook(coupon.book.id)}
              />
            </View>
          ))}
      </View>
    </ScrollView>
  );
};

export default ProfileScreen;
