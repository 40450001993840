import {ExtendedBookData} from './Queries';

export const calculateBookProgress = (bookData: ExtendedBookData) => {
  if (!bookData.latestChapterRead) {
    return 0;
  }

  const numChapters = bookData.chapters.length;

  if (numChapters === 0) {
    return 0;
  }

  const latestChapterIndex = bookData.latestChapterRead.chapterIndex - 1;
  const latestChapterPercent = bookData.latestChapterRead.percentComplete;

  return (
    (latestChapterIndex / numChapters) * 100 +
    latestChapterPercent / numChapters
  );
};