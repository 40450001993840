import React from "react";
import { Linking, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Text, Pressable, View } from "react-native";

import AppStyles from "../AppStyles";
import { useAuthContext } from "../context/AuthContext";
import { gql, useMutation, useQuery } from "@apollo/client";

const BORDER_STYLE = {
  borderBottomWidth: 1,
  borderBottomColor: "#283139",
};

const TWITTER_LINK = "https://twitter.com/getstori";

const RESET_PURCHASES = gql`
  mutation ResetPurchases {
    resetPurchasesDebug {
      id
    }
  }
`;

const SettingsRow = ({
  text,
  onPress,
}: {
  text: string;
  onPress: () => void;
}) => {
  return (
    <Pressable
      style={{
        ...BORDER_STYLE,
        padding: 24,
      }}
      onPress={onPress}
    >
      <Text style={{ color: AppStyles.text.mainText.color, fontSize: 18, 
                      fontFamily: AppStyles.text.secondaryText.font, }}>
        {text}
      </Text>
    </Pressable>
  );
};

const SettingsScreen = ({ onBackPress }: { onBackPress: () => void }) => {
  const { logout } = useAuthContext();

  const [resetPurchases] = useMutation(RESET_PURCHASES);

  const resetPurchaseAndReload = async () => {
    await resetPurchases();
    location.reload();
  };

  return (
    <View
      style={{
        flex: 1,
        zIndex: 2,
        backgroundColor: AppStyles.pageBackgroundColor,
      }}
    >
      <Pressable
        style={{
          ...BORDER_STYLE,
          flexDirection: "row",
          padding: 12,
          alignItems: "center",
        }}
        onPress={onBackPress}
      >
        <Ionicons name="chevron-back" color="white" size={24} />
        <Text
          style={{
            color: AppStyles.text.mainText.color,
            fontSize: 24,
            fontWeight: "bold",
            marginLeft: 8,
            fontFamily: AppStyles.text.secondaryText.font,
          }}
        >
          Settings
        </Text>
      </Pressable>
      <SettingsRow
        text="Contact Us"
        onPress={() => {
          if (Platform.OS === "web") {
            window.open(TWITTER_LINK);
          } else {
            Linking.openURL(TWITTER_LINK);
          }
        }}
      />
      {/* <SettingsRow
        text="Reset Purchases (DEBUG ONLY)"
        onPress={() => resetPurchaseAndReload()}
      /> */}
      <SettingsRow
        text="Log Out"
        onPress={() => {
          logout();
          onBackPress();
          location.reload();
        }}
      />
    </View>
  );
};

export default SettingsScreen;
