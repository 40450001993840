import React from "react";
import { Text } from "react-native";

import AppStyles from "../AppStyles";

const HomeScreenTitle = ({ text }: { text: String }) => {
  return (
    <Text
      style={{
        fontSize: 20,
        color: AppStyles.text.mainText.color,
        fontFamily: AppStyles.text.secondaryText.font,
      }}
    >
      {text}
    </Text>
  );
};

export default HomeScreenTitle;
